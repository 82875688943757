import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { appDownloads, appSubmitForm } from "../../../../routes/routes";
import * as styles from "./appIntroContentStyles";
import axios from "axios";

const AppIntroContent = ({ bannerPage }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCTASubmit = (submitData) => {
    console.log(submitData, "data");
    const data = {
      name: submitData.name,
      email: submitData.email,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/auth/waitlist`,
        data,
        config
      )
      .then((response) => {
        console.log(response.data);
        const data = {
          title: "Get Early Access",
          description: "Subscribe to get early access to AutoFlow Studio",
          src: "https://tally.so/embed/3lydqB?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1",
        };
        navigate(appDownloads, { state: { data: data } });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.introWrapper}>
        <div className="flex flex-col sm:flex-row gap-2 items-center">

          <a href="https://www.producthunt.com/posts/autoflow-studio?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-autoflow&#0045;studio" 
            target="_blank"
            className="relative w-52 sm:w-60"
          >
            <div className="absolute -translate-y-[1px] z-10 bg-[#3d3d3d] shadow-lg w-full h-full inset-0 mx-auto rounded-[10px]">

            </div>
            <img 
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=626609&theme=dark&period=daily" 
              alt="AutoFlow&#0032;Studio - Ship&#0032;faster&#0032;and&#0032;test&#0032;smarter&#0032;with&#0032;simplified&#0032;AI&#0045;powered&#0032;QA | Product Hunt" 
              className="object-contain"
              style={{ position:"relative", zIndex: 40}} 
            />
          </a>

          <a
            href="https://www.producthunt.com/posts/autoflow-studio?embed=true&utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-autoflow&#0045;studio" 
            target="_blank"
            className="relative w-52 sm:w-60"
          >
              <div className="absolute -translate-y-[1px] z-10 bg-[#383838] shadow-lg w-full h-full inset-0 mx-auto rounded-[10px]"></div>
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=626609&theme=dark&period=weekly&topic_id=237" 
                alt="AutoFlow&#0032;Studio - Ship&#0032;faster&#0032;and&#0032;test&#0032;smarter&#0032;with&#0032;simplified&#0032;AI&#0045;powered&#0032;QA | Product Hunt" 
                className="object-contain"
                style={{ position:"relative", zIndex: 40}} 
              />
          </a>
        </div>
        {/* <div className={styles.introBox}>
          <span className={styles.introbg}></span>
          <span className={styles.introIcon}>✦</span>
          <a target="_blank" href="https://dl.getautoflowstudio.com/book-a-demo" className="font-display font-normal flex flex-col items-center sm:flex-row">
            <p className="whitespace-nowrap">
              Thanks for your support!
            </p>
          </a>
        </div> */}
      </div>
      <h1
        className={`${styles.mainHeading} ${
          bannerPage === true ? "text-3xl md:text-5xl" : "text-5xl md:text-7xl"
        } font-heading`}
      >
        Automate Tests <span className="font-semibold">10X Faster</span>
      </h1>
     
      <p
        className={`${styles.subHeading} ${
          bannerPage === true ? "mb-8" : "mb-2 sm:mb-14"
        } font-display`}
      >
        No-code Cross-Browser Testing for Complex Flows
      </p>
      {/* {bannerPage !== true && (
        <div
          className={`hidden md:block md:absolute top-[180px] md:top-60 -left-14`}
        >
          <p className="text-secondary-color-light/30 text-xs font-display">
            Exclusive <br /> 15 days free trial.
          </p>
        </div>
      )} */}

      {/* {bannerPage !== true && (
        <svg
          className={`hidden md:absolute top-[200px] md:top-64 left-6 h-[60px] mt-4 fill-primary-color/50 opacity-50 md:block sm:-left-7 sm:mt-5`}
          viewBox="0 0 61 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.54 57.568c.13-.38-.9-.23-1.81-.28-.27-.02-.58-.13-.75-.14-.59-.03-1.24.05-1.94.01-1.48-.13-3-.34-4.55-.49-1.54-.23-3.1-.54-4.67-.81-1.54-.41-3.11-.73-4.62-1.2-1.49-.52-3.02-.91-4.42-1.55-1.13-.49-2.35-.94-3.39-1.54-1.08-.6-2.11-1.17-3.08-1.82-.35-.21-.72-.3-1.08-.5-.33-.19-.53-.45-.84-.64-.75-.44-1.41-.87-2.13-1.36-.4-.29-.79-.58-1.19-.87l-1.13-.97c-.75-.66-1.53-1.28-2.24-1.98-.72-.69-1.53-1.27-2.22-1.98-1.82-1.86-3.49-3.8-5.04-5.88-1.08-1.37-2.11-2.74-3.08-4.2-.83-1.32-1.62-2.75-2.37-4.15-1.64-3-3.21-6.09-4.43-9.29-.23-.63-.37-1.24-.6-1.86-.88-2.53-1.9-5.19-2.59-7.95-.33-1.3-.65-2.62-.94-3.92-.26-1.17-.42-2.64-.93-3.47-.38.13-.28.67-.26 1.44.03.82.23 1.36.5 2.52.6 2.47 1.15 5.14 2.4 8.89 2.42 7.19 6.01 15 11.49 22.29.35.47.71.96 1.09 1.47.41.48.83.98 1.26 1.49 1.66 1.95 3.62 3.87 5.68 5.72 1.06.88 2.13 1.78 3.23 2.61 1.14.78 2.21 1.64 3.36 2.32 1.02.6 2 1.31 3.05 1.86 1.05.55 2.08 1.15 3.14 1.63.67.28 1.38.58 2.08.88.7.3 1.41.58 2.11.79 1.39.44 2.61.92 3.47 1.18.98.22 1.95.44 2.91.66.95.25 1.92.33 2.86.5.95.13 1.87.35 2.8.42.92.09 1.84.19 2.73.28 1.51.2 2.64.24 4.21.19.61-.03 1.78-.02 1.94-.31l-.01.01Z"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.4 53.438c-.32.21-.1.19-.07.28.01.03-.05.11-.05.13.03.06.15.06.18.12.29.57.75 1.12 1.15 1.63.08.1.15.22.21.34.06.12.03.29.1.4.02.04.15 0 .18.03s-.05.12-.03.15c.06.07.17.09.22.17.11.18.15.42.27.59.06.09.19.13.26.21.12.15.21.33.31.5l.06-.64c-.06.05-.12.11-.18.16-.13.13-.19.33-.31.47-.11.12-.29.19-.41.31-.26.26-.43.61-.7.84-.05.05-.16.03-.22.07-.22.18-.3.52-.53.73-.1.1-.19.21-.28.33-.07.1-.25.15-.03.5.29.28.31.22.44.24.14.02.11-.1.17-.21.14-.24.4-.38.66-.7.51-.62 1.1-1.29 1.79-1.96.06-.06.13-.11.21-.17l.2-.18-.16-.27s-.04-.07-.07-.11c-.35-.41-.72-.9-1.02-1.38-.13-.21-.21-.46-.34-.67-.16-.26-.45-.5-.46-.72-.33-.28-.63-.57-.83-.89-.09-.14-.09-.27-.29-.34-.08-.03-.17-.11-.43.05v-.01Z"
          ></path>
        </svg>
      )} */}
        <div className={styles.buttonContainer}>
          <svg
            className={`hidden sm:block sm:absolute top-0 left-0 -translate-x-[100%] h-[60px] fill-primary-color/50 opacity-50 md:block`}
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.54 57.568c.13-.38-.9-.23-1.81-.28-.27-.02-.58-.13-.75-.14-.59-.03-1.24.05-1.94.01-1.48-.13-3-.34-4.55-.49-1.54-.23-3.1-.54-4.67-.81-1.54-.41-3.11-.73-4.62-1.2-1.49-.52-3.02-.91-4.42-1.55-1.13-.49-2.35-.94-3.39-1.54-1.08-.6-2.11-1.17-3.08-1.82-.35-.21-.72-.3-1.08-.5-.33-.19-.53-.45-.84-.64-.75-.44-1.41-.87-2.13-1.36-.4-.29-.79-.58-1.19-.87l-1.13-.97c-.75-.66-1.53-1.28-2.24-1.98-.72-.69-1.53-1.27-2.22-1.98-1.82-1.86-3.49-3.8-5.04-5.88-1.08-1.37-2.11-2.74-3.08-4.2-.83-1.32-1.62-2.75-2.37-4.15-1.64-3-3.21-6.09-4.43-9.29-.23-.63-.37-1.24-.6-1.86-.88-2.53-1.9-5.19-2.59-7.95-.33-1.3-.65-2.62-.94-3.92-.26-1.17-.42-2.64-.93-3.47-.38.13-.28.67-.26 1.44.03.82.23 1.36.5 2.52.6 2.47 1.15 5.14 2.4 8.89 2.42 7.19 6.01 15 11.49 22.29.35.47.71.96 1.09 1.47.41.48.83.98 1.26 1.49 1.66 1.95 3.62 3.87 5.68 5.72 1.06.88 2.13 1.78 3.23 2.61 1.14.78 2.21 1.64 3.36 2.32 1.02.6 2 1.31 3.05 1.86 1.05.55 2.08 1.15 3.14 1.63.67.28 1.38.58 2.08.88.7.3 1.41.58 2.11.79 1.39.44 2.61.92 3.47 1.18.98.22 1.95.44 2.91.66.95.25 1.92.33 2.86.5.95.13 1.87.35 2.8.42.92.09 1.84.19 2.73.28 1.51.2 2.64.24 4.21.19.61-.03 1.78-.02 1.94-.31l-.01.01Z"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.4 53.438c-.32.21-.1.19-.07.28.01.03-.05.11-.05.13.03.06.15.06.18.12.29.57.75 1.12 1.15 1.63.08.1.15.22.21.34.06.12.03.29.1.4.02.04.15 0 .18.03s-.05.12-.03.15c.06.07.17.09.22.17.11.18.15.42.27.59.06.09.19.13.26.21.12.15.21.33.31.5l.06-.64c-.06.05-.12.11-.18.16-.13.13-.19.33-.31.47-.11.12-.29.19-.41.31-.26.26-.43.61-.7.84-.05.05-.16.03-.22.07-.22.18-.3.52-.53.73-.1.1-.19.21-.28.33-.07.1-.25.15-.03.5.29.28.31.22.44.24.14.02.11-.1.17-.21.14-.24.4-.38.66-.7.51-.62 1.1-1.29 1.79-1.96.06-.06.13-.11.21-.17l.2-.18-.16-.27s-.04-.07-.07-.11c-.35-.41-.72-.9-1.02-1.38-.13-.21-.21-.46-.34-.67-.16-.26-.45-.5-.46-.72-.33-.28-.63-.57-.83-.89-.09-.14-.09-.27-.29-.34-.08-.03-.17-.11-.43.05v-.01Z"
            ></path>
          </svg>
          <div
            className={`sm:absolute -top-9 left-0 sm:-translate-x-[125%]`}
          >
            <p className="text-secondary-color-light/30 text-xs font-display  whitespace-nowrap sm:whitespace-normal">
              Exclusive <br /> 15 days free trial.
            </p>
          </div>
          <div className="relative overflow-hidden rounded-2xl p-[1px]">
            
            <span className={styles.spinner}></span>
            <a
              target="_blank"
              href={`https://dl.getautoflowstudio.com/${ window.navigator.platform.includes("Mac") ? "mac" : "windows" }`}
              className="w-full flex flex-col md:flex-row items-center justify-center gap-y-2 sm:space-x-2 border border-secondary-color-light/5 rounded-2xl p-2  bg-[linear-gradient(110deg,_theme(colors.zinc.950),45%,_theme(colors.zinc.900),55%,_theme(colors.zinc.950))] bg-[length:200%_100%] backdrop-blur-md animate-background-shine"
            >
              <button className="whitespace-nowrap font-bold font-heading border border-secondary-color-light/10 text-center px-10 sm:px-8 md:px-14 py-2 sm:py-[11px] md:py-4 bg-primary-color rounded-xl w-full text-sm sm:text-base md:text-lg text-black backdrop-blur-md">
                Download for { window.navigator.platform.includes("Mac") ? "Mac" : "Windows" }
              </button>
            </a>
            
          </div>
          <div className="relative overflow-hidden rounded-2xl p-[1px] sm:ml-2">
            
            <span className="absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,_theme(colors.zinc.600)_0%,_theme(colors.zinc.900)_50%,_theme(colors.zinc.600)_100%)]"></span>
            <a
              target="_blank"
              href="https://dl.getautoflowstudio.com/book-a-demo"
              className="w-full flex flex-col md:flex-row items-center justify-center gap-y-2 sm:space-x-2 border border-secondary-color-light/5 rounded-2xl p-2  bg-[linear-gradient(110deg,_theme(colors.zinc.950),45%,_theme(colors.zinc.900),55%,_theme(colors.zinc.950))] bg-[length:200%_100%] backdrop-blur-md animate-background-shine"
            >
              <button className="font-bold font-heading border border-transparent text-center px-10 sm:px-8 md:px-14 py-2 sm:py-[11px] md:py-4 rounded-xl w-full text-sm sm:text-base md:text-lg bg-secondary-color-dark/20 text-text-primary">
                Book a FREE Demo
              </button>
            </a>
            
          </div>
        </div>
        <div class="flex justify-between md:justify-start items-center space-x-1 sm:space-x-4 mt-4 text-sm text-gray-300">
          <div class="flex items-center justify-center md:justify-normal w-1/3 sm:w-auto text-left gap-2">
            <svg class="w-4 h-4 md:mr-1 text-white" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            FREE Forever
          </div>
          <div class="flex items-center justify-center md:justify-normal w-1/3 sm:w-auto text-left gap-2">
            <svg class="w-4 h-4 md:mr-1 text-white" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            No credit card required
          </div>
          <div class="flex items-center justify-center md:justify-normal w-1/3 sm:w-auto text-left gap-2">
            <svg class="w-4 h-4 md:mr-1 text-white" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            No coding required
          </div>
        </div>
            
      </div>
  );
};

export default AppIntroContent;
