


import React, { useState, forwardRef } from 'react'
import { getColorFromTailwindClass } from '../../../../utils/utlilityFunctions/getColorFromTailwindClass';
/**
 * A Primary Button.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} [props.iconLeft] - The icon to display on the left side.
 * @param {React.ReactNode} [props.iconRight] - The icon to display on the right side.
 * @param {string} [props.textClass] - The class for main text.
 * @param {string} [props.bgColorClass] - The class for button's background color.
 * @param {boolean} [props.slim] - for small button.
 * @param {string} [props.className] - extra classNames for the button.
 */

const PrimaryButton = (props, ref) => {

    const actualBgColor = getColorFromTailwindClass(props.bgColorClass, 'backgroundColor');
    const [bgColor, setBgColor] = useState(actualBgColor);

    function adjustLuminescence(adjustment) {
        // Extract the HSL values using regex
        const hslPattern = /hsla?\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\s*(,\s*[\d.]+)?\)/;
        const match = actualBgColor.match(hslPattern);

        if (!match) {
            throw new Error("Invalid HSL/HSLA color format");
        }

        const hue = parseInt(match[1]);
        const saturation = parseFloat(match[2]);
        let lightness = parseFloat(match[3]);
        const alpha = match[4] ? match[4].trim() : "";

        // Adjust the lightness (luminescence), keeping it within [0, 100]
        lightness = Math.min(100, Math.max(0, lightness + adjustment));

        // Return the new HSL or HSLA color string
        return `hsl${alpha ? "a" : ""}(${hue}, ${saturation}%, ${lightness}%${alpha})`;
    }


    const handleMouseOver = () => {
        setBgColor(adjustLuminescence(-9));
    }

    const handleMouseOut = () => {
        setBgColor(actualBgColor);
    }

    const handleMouseDown = () => {
        setBgColor(adjustLuminescence(-18));
    }

    const handleMouseUp = () => {
        setBgColor(adjustLuminescence(-9));
    }

    const handleFocus = () => {
        setBgColor(adjustLuminescence(-9));
    }


    return (
        <button className={`${props.textClass} ${ props.className } flex items-center justify-center gap-2 ${  props.skinny ? " py-1 text-body-md " : props.slim ? " py-2 text-body-md " : " py-3 " } px-5 font-medium rounded`}
            style={{
                backgroundColor: bgColor
            }}
            type={props?.type}
            onClick={props?.onClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onFocus={handleFocus}
            ref={ref}
        >
            {
                props.iconLeft && props.iconLeft
            }
            {
                props.children
            }
            {
                props.iconRight && props.iconRight
            }
        </button>
    )
}

export default forwardRef(PrimaryButton);