import React from 'react'
import ModalHeading from '../../../AppLandingPageComponents/landingPageComponents/JourneyRunReport/ModalHeading';
import PrimaryButton from './PrimaryButton';
import TertiaryButton from './TertiaryButton';

function ConfirmationModal({
  closeModal,
  onConfirm,
  modalId = 'confirmation_modal',
  heading = 'Confirm Action',
  message,
  confirmText = 'Yes',
  cancelText = 'No',
  confirmButtonProps = {},
  cancelButtonProps = {}
}) {
  return (
    <dialog id={modalId} className="modal">
      <div className="modal-box w-[80%] sm:w-3/5 sm:max-w-2xl p-2 sm:p-6 rounded-md bg-[#121517] border border-border-primary">
        <ModalHeading
          data={{
            heading: heading,
            onClickFn: closeModal,
          }}
        />
        <div className='mt-6 text-white flex text-body-md font-[450]'>
          {message}
        </div>
        <div className="flex gap-2 justify-end mt-10 w-full sm:w-1/4 sm:ml-auto">
          <TertiaryButton
            slim
            className="w-1/2"
            onClick={() => {
              closeModal();
            }}
            {...cancelButtonProps}
          >
            {cancelText}
          </TertiaryButton>
          <PrimaryButton
            slim
            className="w-1/2"
            onClick={() => {
              onConfirm();
              closeModal();
            }}
            textClass="text-selected-shadow"
            bgColorClass="bg-primary-color"
            {...confirmButtonProps}
          >
            {confirmText}
          </PrimaryButton>
        </div>
      </div>
    </dialog>
  )
}

export default ConfirmationModal