import React from 'react';
import { useNavigate } from "react-router-dom";
import AlternateButton from "./AlternateButton";
import { observer } from "mobx-react";
import testStore from '../../../../stores/TestStore';



const WorkspaceMembers = ({ workspaceName = "My Team" }) => {
  const navigate = useNavigate();

  

  return (
    <div className="bg-Surface-Raised-Primary rounded-lg border border-border-primary p-2 sm:p-4 hover:border-surface-accent-brand-overlay-primary transition-colors h-full">
      {/* Header */}
      <div className="flex flex-col gap-2 mb-4 pb-2 border-b border-border-primary">
        {/* Workspace Name */}
        <div className="flex items-center gap-2">
          <svg
            className="w-4 h-4 text-text-subtlest"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
            />
          </svg>
          <span className="text-text-primary font-medium">{workspaceName}</span>
        </div>
        
        {/* Members Section Title & Action */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <svg
                className="w-4 h-4 text-text-subtlest"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <span className="text-text-subtle">Members</span>
            </div>
            {/* Member count badge */}
            <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-surface-accent-brand-primary text-brand">
              {testStore.teamMembers?.members?.length}
            </span>
          </div>
          {/* <AlternateButton
            variant="brand"
            size="sm"
          >
            Invite Member
          </AlternateButton> */}
        </div>
      </div>

      {/* Members List */}
      <div className="space-y-3 overflow-y-auto h-[calc(100%-100px)]">
        {testStore.teamMembers?.members?.length > 0 ? (
          testStore.teamMembers?.members?.map(member => (
            <div 
              key={member.userId} 
              className="flex items-center justify-between p-3 hover:bg-alpha-700 rounded-md transition-colors"
            >
              {/* Left: Avatar & Info */}
              <div className="flex items-center gap-3">
                <div className="relative">
                  <div className="w-9 h-9 rounded-full bg-surface-accent-brand-primary text-brand flex items-center justify-center font-medium">
                    {member.name.charAt(0)}
                  </div>
                  {/* <div className={`absolute -bottom-0.5 -right-0.5 w-3 h-3 rounded-full border-2 border-Surface-Raised-Primary
                    ${member.online ? 'bg-text-accent-green-primary' : 'bg-text-subtlest'}`}
                  /> */}
                </div>
                
                <div>
                  <div className="text-text-primary font-medium flex items-center gap-2">
                    {member.name}
                    {/* {member.isCurrentUser && (
                      <span className="text-xs text-text-subtlest">(you)</span>
                    )} */}
                  </div>
                  <div className="text-text-subtlest text-xs flex items-center gap-2">
                    <span>{member.email}</span>
                     
                  </div>
                </div>
              </div>

              {/* Right: Role Badge */}
              {/* <div className="flex items-center gap-3">
                <span 
                  className={`px-2 py-1 rounded-md text-xs font-medium
                    ${member.role === 'admin' 
                      ? 'bg-surface-accent-purple-primary text-text-accent-purple-primary' 
                      : 'bg-surface-accent-blue-primary text-text-accent-blue-primary'
                    }`}
                >
                  {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                </span>
              </div> */}
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full py-16 px-4 text-center">
            <svg
              className="w-16 h-16 text-text-subtlest mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <p className="text-text-subtle font-medium mb-2">Just You Here</p>
            <p className="text-text-subtlest text-sm">
              Invite team members to start collaborating
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkspaceMembers;