import React, { useState } from 'react'
/**
 * A Primary Button.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Funtion} [props.onClick] - The function which handles the click on this button.
 * @param {React.ReactNode} [props.iconLeft] - The icon to display on the left side.
 * @param {React.ReactNode} [props.iconRight] - The icon to display on the right side.
 */
const TertiaryButton = (props) => {
    
    const [ bgClass, setBgClass ] = useState("bg-alpha-700")
    const handleMouseOver = () => {
        setBgClass("bg-alpha-600");
    }
    
    const handleMouseOut = () => {
        setBgClass("bg-alpha-700");
    }
    
    const handleMouseDown = () => {
        setBgClass("bg-alpha-500");
    }
    
    const handleMouseUp = () => {
        setBgClass("bg-alpha-600");
    }
    const handleFocus = () => { }
  return (
    <button onClick={props.onClick} className={`${ bgClass } ${ props.className } flex items-center  gap-2 px-4 py-3 font-500 rounded-sm text-text-accent-grey-primary`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onFocus={handleFocus}
    >
        {
            props.iconLeft && props.iconLeft
        }
        {
            props.children
        }
        {
            props.iconRight && props.iconRight
        }
    </button>
  )
}
export default TertiaryButton