import React from 'react';
import { observer } from "mobx-react";
import testStore from '../../../../stores/TestStore';

const MetricHeader = ({ icon, title, className = '' }) => (
  <div className={`flex flex-wrap sm:flex-row items-center gap-2 ${className}`}>
    {icon}
    <span className="text-text-subtle font-medium text-sm sm:text-start text-center">{title}</span>
  </div>
);

const MetricValue = ({ 
  value, 
  unit, 
  remainder, 
  remainderUnit, 
  trend, 
  trendValue,
  valueClassName = "text-text-primary",
  trendClassName = "text-text-accent-green-primary"
}) => (
  <div className="flex flex-col justify-between">
    <div className="flex  sm:flex-row flex-wrap items-baseline gap-2">
      <div className="flex items-baseline">
        <h3 className={`text-4xl font-display ${valueClassName}`}>{value}</h3>
        {unit && <p className="text-2xl text-text-subtle">{unit}</p>}
      </div>
      {remainder != null && (
        <div className="flex items-baseline">
          <h3 className={`text-4xl font-display ${valueClassName}`}>{remainder}</h3>
          {remainderUnit && <p className="text-2xl text-text-subtle">{remainderUnit}</p>}
        </div>
      )}
      {trend && (
        <div className={`flex items-center ${trendClassName}`}>
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d={trend === 'up' 
                ? "M5 10l7-7m0 0l7 7m-7-7v18" 
                : "M19 14l-7 7m0 0l-7-7m7 7V3"} 
            />
          </svg>
          <span className="text-sm whitespace-nowrap  ">{trendValue}</span>
        </div>
      )}
    </div>
  </div>
);


const MetricsCard = ({
  metrics,
  className = '',
  cardClassName = '',
  headerClassName = '',
  metricsClassName = '',
  showDividers = true,
}) => {
  return (
    <div className={` bg-Surface-Raised-Primary rounded-lg p-2 sm:p-4 border border-border-primary relative hover:border-surface-accent-brand-overlay-primary transition-colors ${cardClassName}`}>
   
        {/* Headers */}
      <div className={`grid grid-cols-${metrics.length} gap-1 lg:gap-6 mb-4 pb-4 border-b border-border-primary ${headerClassName}`}>
        {metrics.map((metric, index) => (
          <MetricHeader
            key={`header-${index}`}
            icon={metric.icon}
            title={metric.title}
            className={`${index !== 0 && showDividers ? 'border-l border-border-primary justify-start pl-2 ' : ''}`}
          />
        ))}
      </div>

      {/* Metrics */}
      <div className={`grid grid-cols-${metrics.length} gap-2 lg:gap-6 ${metricsClassName}`}>
        {metrics.map((metric, index) => (
          <div
            key={`metric-${index}`}
            className={`${index !== 0 && showDividers ? 'border-l border-border-primary pl-2 sm:pl-2 ' : ''}`}
          >
            {
              testStore.loadingTimeAndRunStats ? (
                <div className="flex flex-col gap-2 items-start justify-center h-full">
                  <span className="loading loading-ring loading-lg text-text-subtle"></span>
                  <span className="loading loading-dots loading-xs text-text-subtle ml-2"></span>
                </div>
              ) :
                (
                  <>
                    <MetricValue
                      value={metric.value}
                      unit={metric.unit}
                      remainder={metric.remainder}
                      remainderUnit={metric.remainderUnit}
                      trend={metric.trend}
                      trendValue={metric.trendValue}
                      valueClassName={metric.valueClassName}
                      trendClassName={metric.trendClassName}
                    />
                    {metric.subtitle && (
                      <p className="text-text-subtlest text-sm mt-2 ">{metric.subtitle}</p>
                    )}
                  </>
                )
            }
          </div>
        ))}
      </div>

    </div>
  );
};



export default observer(MetricsCard);