import axios from 'axios';
import Session from "supertokens-web-js/recipe/session";
import TestRunItem from '../components/TestRunItem';
// import { doesSessionExist } from '../../../helpers/utlity';


export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_STUDIO_BASE_URL}`, // Replace with your actual base URL
});

export const fetchIssues = async ({
    workspaceId,
    status,
    pageNumber = 1,
    pageSize = 10
}) => {

    console.log("fetchIssues", pageNumber);
    try {
        // await doesSessionExist();
        const token = await Session.getAccessToken();

        if (!token) {
            throw new Error('Authentication token not available');
        }

        // Build query parameters
        const queryParams = new URLSearchParams();
        
        if (workspaceId) queryParams.append('workspace_id', workspaceId);
        if (status) queryParams.append('status', status);
        queryParams.append('page_number', pageNumber.toString());
        queryParams.append('page_size', pageSize.toString());

        const response = await apiClient.get(
            `/api/v1/analytics-dashboard/issues?${queryParams}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.status < 200 || response.status >= 300) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return response?.data;
    } catch (error) {
        if (error.response) {
            console.error('Error fetching issues:', error.response.data);
            throw error.response.data;
        } else {
            console.error('Error:', error);
            throw error;
        }
    }
};


export const transformIssuesData = (issues) => {
    if(!Array.isArray(issues)){
      return [];
    }
   
    return issues?.map(issue => ({
      id: issue.id,
      originalData: issue,
      issueStatus: issue?.issueStatus, 
      issueNumber: `#${issue.issueIndex || 'N/A'}`,
      runBy: issue.runByUser || 'Unknown',
      runType: issue.runType,
      testSuiteName: issue.bucketName || 'Unknown Suite',
      journeyName: issue.journeyName || 'Unknown Journey',
      status: issue?.issueType, 
      timestamp: new Date(issue.runAt).toLocaleString(),
      flows: [
        {
          name: issue.flowName || 'Unknown Flow',
          status: issue?.issueType,
          steps: [
            {
              name: issue.stepName || 'Unknown Step',
              status: issue?.issueType,
              error: issue.issueDetails?.autoFlowErrorMessage || 
                     issue.issueDetails?.errorMessage || 
                     issue.issueDetails?.message || 
                     'Unknown error',
              type: issue.stepType
            }
          ]
        }
      ]
    }));
  };


  export function renderAnalyticsItems(items, modalId, filter = "") {
    const filteredItems = filter?.trim() 
        ? items.filter((item) => item.issueStatus === filter)
        : items;
    
    const sortedItems = filteredItems.sort((a, b) => 
        new Date(b.originalData.runAt) - new Date(a.originalData.runAt)
    );

    return sortedItems.map((item) => (
        <TestRunItem key={item.id} item={item} modalId={modalId} />
    ));
}
