import { observer } from "mobx-react";
import { JorneyIcon, TestSuite, OutlineWaves, UpArrow, DownArrow, Bandage } from "../../../../assets/shared_icons";
import AlternateButton from "./AlternateButton";
import Badge from "./Badge";
import testStore from "../../../../stores/TestStore";
import renderIcon from "./renderIcon";
import React,{ useState } from "react";
import SessionReact from "supertokens-auth-react/recipe/session";
import Session from "supertokens-web-js/recipe/session";
import { isMobile } from 'react-device-detect';



const TestRunItem = ({ item , modalId = "confirmation_modal"}) => {

    const openApp = async() => {
      let loginParams=null;
      let redirectParams=null;
      if (await SessionReact.doesSessionExist()) {
        let userId = await Session.getUserId();
        let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
        console.log("User Data is", accessTokenPayload);
        let jwt = await Session.getAccessToken();
        console.log("User ID is", userId);
        console.log("JWT is", jwt);
        loginParams = `&accessToken=${jwt}&userId=${userId}&email=${accessTokenPayload?.user_info?.email}&organisation=${accessTokenPayload?.user_info?.organisation_name}&name=${accessTokenPayload?.user_info?.first_name + " " + accessTokenPayload?.user_info?.last_name}`;
    }
      const bucketId = item?.originalData?.bucketId;
      const flowId = item?.originalData?.flowId;
      const journeyId = item?.originalData?.journeyId;
      const stepId = item?.originalData?.stepId;
      const issueId = item?.id;
      testStore.setCurrentSelectedIssue(issueId)
      const link = document.createElement('a');
      redirectParams= `autoflow://browserView?flowId=${flowId}&issueId=${issueId}&journeyId=${journeyId}&bucketId=${bucketId}&stepId=${stepId}`;
      if(loginParams){
        link.href = redirectParams+loginParams;
      }
      else{
      link.href = redirectParams;
      }
      document.body.appendChild(link);
      link.click();
    };
  
    const [isExpanded, setIsExpanded] = useState(false);
  
    return (
      <div className="border my-3 sm:mx-1 border-border-primary rounded-lg overflow-hidden bg-surface-primary hover:bg-alpha-700 transition-colors" id={item?.id}>
        {/* Main Header */}
        <div 
          className="sm:p-4 p-1 cursor-pointer flex items-start gap-2"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {/* Status Indicator */}   
          <JorneyIcon className={`w-4 h-4 mt-1 ${item?.issueStatus === "CLOSED" ? "text-text-accent-green-primary" : "text-text-accent-red-primary"}`} />
          {/* Title and Basic Info */}
          <div className="flex-1">
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between items-start">
            <div className="min-w-0 flex-1"> 
            <h4 className="font-medium text-base flex items-center gap-1 flex-wrap">
              <span className="text-text-subtle truncate sm:max-w-[200px] max-w-[150px]" title={item?.journeyName}> 
                {item?.journeyName}
              </span>
                  <Badge
                    textClass={item?.runType === "CLOUD" ? "text-sky-300" : "text-indigo-300"}
                    bgColorClass={item?.runType === "CLOUD" ? "bg-sky-900/30" : "bg-indigo-900/30"}
                    size="md"
                  >
                    {item?.runType === "CLOUD" ? "Cloud" : "Local"}
                  </Badge>
                </h4>
                <p className="flex items-center gap-1 text-sm font-medium text-text-subtlest">
                 <TestSuite className={`w-4 h-4 mt-1 ${item?.issueStatus === "CLOSED" ? "text-text-accent-green-primary" : "text-text-accent-red-primary"}`} />
                 <span className="truncate sm:max-w-[200px] max-w-[150px]" title={item?.testSuiteName}>
                   {item?.testSuiteName}
                 </span>
               </p>
              </div>
              <div className="flex items-center gap-2">
                {
                  item?.issueStatus === "OPEN" &&!isMobile? (
                  
                    <AlternateButton
                      variant="brand"
                      size="md"
                      className='hidden sm:flex'
                      icon={Bandage}
                      iconSize="sm"
                      onClick={openApp}
                    >
                        Fix Test
                    </AlternateButton>
                  ):
                  null
                }       
            
                 <AlternateButton
                      variant="green"
                      size="md"
                      icon={item?.issueStatus === "CLOSED" ? "M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" : ""}
                      onClick={() => {
                        if (item?.issueStatus === "CLOSED") {
                      
                        } else {
                       
                          testStore.setCurrentSelectedIssue(item);
                          document.getElementById(modalId).showModal();
                        }
                      }}

                      noHover={item?.issueStatus === "CLOSED"}
                    >
                        {item?.issueStatus === "CLOSED" ? "Resolved" : "Mark Resolved"}
                    </AlternateButton>
                
              </div>
            </div>
            <div className="flex gap-2 mt-1 text-xs text-text-subtlest border-t border-border-primary pt-1">
              <span>{item?.issueNumber}</span>
              <span>•</span>
              <span>
                <span>run by </span>
                <span className="truncate max-w-[150px] inline-block align-bottom" title={item?.runBy}>{item?.runBy}</span>
                <span> on {item?.timestamp}</span>
              </span>
            </div>
          </div>
          <div className="flex items-center self-stretch">
          {isExpanded ? (
             <UpArrow className="w-5 h-5 text-text-subtle" />   
              ) : (
              <DownArrow className="w-5 h-5 text-text-subtle" />
            )}
         </div>
        </div>
  
        {/* Expanded Content */}
        {isExpanded && (
          <div className="border-t border-border-primary bg-alpha-700 p-2 sm:p-4">
            {/* Flows Section */}
            <div className="space-y-4">
              {item.flows?.map((flow, fidx) => (
                <div key={fidx} className="sm:pl-4 pl-2 border-l border-border-primary">
                  <div className="flex items-center gap-2 mb-2 -ml-1">
                    <OutlineWaves className={`w-3 h-3 mt-1 ${item?.issueStatus === "CLOSED" ? "text-text-accent-green-primary" : "text-text-accent-red-primary"}`} />
                    <h5 className="font-medium text-text-primary">{flow.name}</h5>
                  </div>
                  
                  {/* Steps */}
                  <div className="ml-4 space-y-3">
                    {flow.steps?.map((step, sidx) => (
                      <div key={sidx} className="border-l border-border-primary pl-2 sm:pl-4 -ml-3.5">
                        <div className="flex items-center gap-2 mb-1 -ml-1.5">
                          {renderIcon(step, `w-3 h-3 mt-1 flex-shrink-0 ${item?.issueStatus === "CLOSED" ? "text-text-accent-green-primary" : "text-text-accent-red-primary"}`)}
                          <span className={`text-sm text-text-accent-red-primary`}>
                             {step.name}
                          </span>
                        </div>
                        
                        {/* Error Details */}
                        {step.error && (
                          <div className=" border-l border-border-primary mt-1 text-sm pl-2 sm:pl-3.5">
                            <div className="bg-surface-accent-red-primary  p-2 rounded">
                              <p className="text-text-accent-red-primary text-xs">{step.error}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };



  export default observer(TestRunItem);