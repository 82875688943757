import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

const AlternateButton = ({ 
    children, 
    onClick, 
    icon: Icon, // Can be either a component or an SVG path
    variant = 'brand', // brand, green, red, etc.
    size = 'md', // sm, md, lg
    disabled = false,
    className = '',
    iconPosition = 'left',
    noHover = false,
    iconSize = 'md',
    ...props
  }) => {
    const baseStyles = "flex items-center gap-1.5 font-medium transition-colors duration-150 ease-in-out rounded-md border border-border-primary";
    
    const variantStyles = {
      brand: {
        base: "bg-surface-accent-brand-primary text-brand",
        hover: "hover:bg-[#443307] hover:text-brand-300 active:bg-surface-accent-brand-overlay-primary"
      },
      green: {
        base: "bg-surface-accent-green-primary text-text-accent-green-primary",
        hover: "hover:bg-secondary-green-primary hover:text-white active:bg-surface-accent-green-overlay-primary"
      },
      red: {
        base: "bg-surface-accent-red-primary text-text-accent-red-primary", 
        hover: "hover:bg-secondary-red-primary hover:text-white active:bg-surface-accent-red-overlay-primary"
      },
      purple: {
        base: "bg-surface-accent-purple-primary text-text-accent-purple-primary",
        hover: "hover:bg-secondary-purple-primary hover:text-white active:bg-surface-accent-purple-overlay-primary"
      }
    };
  
    // Combine styles based on noHover prop
    const variants = Object.entries(variantStyles).reduce((acc, [key, value]) => {
      acc[key] = `${value.base} ${!noHover ? value.hover : ''} disabled:opacity-50 disabled:cursor-not-allowed`;
      return acc;
    }, {});
  
    const sizes = {
      sm: 'px-2 py-1 text-xs',
      md: 'px-3 py-1.5 text-xs',
      lg: 'px-4 py-2 text-sm'
    };
  
    const iconSizes = {
      sm: 'w-3 h-3',
      md: 'w-3.5 h-3.5',
      lg: 'w-4 h-4'
    };
  
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`
          ${baseStyles}
          ${variants[variant]}
          ${sizes[size]}
          ${className}
        `}
        {...props}
      >
        {iconPosition === 'left' && Icon && (
          typeof Icon === 'string' ? (
            <svg className={iconSizes[iconSize]} fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={Icon} />
            </svg>
          ) : (
            <Icon className={iconSizes[iconSize]} />
          )
        )}
        {children}
        {iconPosition === 'right' && Icon && (
          typeof Icon === 'string' ? (
            <svg className={iconSizes[iconSize]} fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={Icon} />
            </svg>
          ) : (
            <Icon className={iconSizes[iconSize]} />
          )
        )}
      </button>
    );
  };

  export default observer(AlternateButton);