import React, { useEffect, useState, useRef, useMemo } from "react";
import Chart from "react-apexcharts";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { UpArrow, DownArrow, OutlineWaves, TestSuite, JorneyIcon, Clickcursor, InputKeyboard, Navigate, Fileupload, Drag, Mailstep, Imagestep, AssertCheck, CheckIcon, Error, EditIcon, Download, Report, LockClosedIcon, FigmaEditIcon, Close } from "../../../assets/shared_icons";
import { observer } from "mobx-react";
import DatePicker from 'react-datepicker'; // Add this import
import testStore from "../../../stores/TestStore";
import Badge from "./components/Badge";
import MiniHistoryTable from "./components/MiniHistoryTable";
import AlternateButton from "./components/AlternateButton";
import WorkspaceMembers from "./components/WorkspaceMembers";
import MetricsCard from "./components/MetricCard";
import { Dropdown } from "./components/Dropdown";
import 'react-datepicker/dist/react-datepicker.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Session from "supertokens-web-js/recipe/session";
// import useSocket from "../../utils/getSocketInstance";
import { v4 as uuidv4 } from "uuid";
import { toJS } from "mobx";
// import { doesSessionExist } from "../../helpers/utlity";
// import { browserView } from "../../helpers/routes";
import { useNavigate } from "react-router-dom";
import TestRunItem from "./components/TestRunItem";
import ConfirmationModal from "./components/ConfirmationModal";
import { fetchIssues, transformIssuesData, renderAnalyticsItems, apiClient } from "./utility/analyticsutility";
import PrimaryButton from "./components/PrimaryButton";
import { SecondaryButton } from "../../Buttons";
import OverlayModal from "../../AppLandingPageComponents/landingPageComponents/JourneyRunReport/OverlayModal";
import axios from "axios";
import TextInputWithState from "./components/TextFields";
import TextSkeleton from "./components/TextSkeleton";
import CustomTooltip from "./components/CustomTooltip";
import { GrClose } from "react-icons/gr";
// import { getFlow, historyData, reportUrl, runStats, timeSaved, workspace } from "./utility/dummyData";

// const { ipcRenderer } = window.require("electron");

const AnalyticsWrapper = () => {
  // Mock data for charts
  const [chartData, setChartData] = useState({
    successData: [],
    failureData: []
  });
  const [timeRange, setTimeRange] = useState('7d');
  
  const [miniHistory, setMiniHistory] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [totalCounts, setTotalCounts] = useState({
    buckets: 0,
    journeys: 0,
    flows: 0
  });
  const [selected, setSelected] = useState("");
  
  // const socket = useSocket();

  const hasMounted = useRef(false);
  const [allIssues, setAllIssues] = useState([]);
  const [pendingIssues, setPendingIssues] = useState([]);
  const [resolvedIssues, setResolvedIssues] = useState([]);
  const [ issueFilter, setIssueFilter] = useState("all");
  const [hasMorePendingIssues, setHasMorePendingIssues] = useState(true);
  const [hasMoreResolvedIssues, setHasMoreResolvedIssues] = useState(true);
  const [hasMoreAllIssues, setHasMoreAllIssues] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);


  const allIssuesPageRef = useRef(1);
  const pendingIssuesPageRef = useRef(1);
  const resolvedIssuesPageRef = useRef(1);
 
  const [organisationId, setOrganisationId] = useState("");
  const [orgUrlErr, setOrgUrlErr] = useState("");
  const [checkingUrl, setCheckingUrl] = useState(false);
  const [orgUrl, setOrgUrl] = useState("");




  // useEffect(()=>{
  //   const responseData = getFlow;
  //   console.log("Response from the server:", responseData);
  //   console.log("Buckets from the server:", responseData.buckets);
  //   let newBucketArr = responseData?.buckets?.map((bucket) => ({
  //     ...bucket,
  //     type: 'bucket',
  //     journeys: bucket?.journeys?.map((journey) => ({
  //       ...journey,
  //       type: 'journey',
  //       flows: journey?.flows
  //         ?.sort((a, b) => a?.journey_sequence_number - b?.journey_sequence_number)
  //         .map((flow) => ({
  //           ...flow,
  //           type: 'flow',
  //         })),
  //     })),
  //   }));

  //   newBucketArr.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  //   testStore.setUserBuckets(newBucketArr);
  //   testStore.setFilterUserBucketsArr(newBucketArr);

  //   testStore.setTimeSavedWithDateRange(timeSaved);
  //   testStore.setAnalyticsChartData(runStats);
  //   testStore.setWorkspaceArr(workspace);
  //   testStore.setWorkspacesForIssues(workspace);


  //   const data = historyData;
  
  //           const transformedBucketRuns = data?.bucketRuns?.map(bucket => ({
  //             ...bucket,
  //             type: 'BucketRun',
  //             title: bucket.bucketName,
  //             id: bucket.bucketRunId,
  //             uniformData: bucket.bucketRunId,
  //           }));
  
  //           const transformedJourneyRuns = data?.journeyRuns?.map(journey => ({
  //             ...journey,
  //             type: 'JourneyRun',
  //             title: journey.journeyName,
  //             id: journey.journeyRunId,
  //             uniformData: journey.journeyRunId,
  //           }));
  
  //           testStore.setAllHistoryData([...transformedBucketRuns, ...transformedJourneyRuns]);

  //           testStore.setLoadingScoreCard(false);
  //           testStore.setScoreCardData(reportUrl);

  // },[])

  
const resolveIssue = async (issueId) => {

 
  try {
      
      const token = await Session.getAccessToken();

      if (!token) {
          throw new Error('Authentication token not available');
      }

      const response = await apiClient.put(
          `/api/v1/analytics-dashboard/resolve-issue/${issueId}`,
          {},
          {
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
          }
      );

     

     
  } catch (error) {
      if (error.response) {
          console.error('Error resolving issue:', error.response.data);
          throw error.response.data;
      } else {
          console.error('Error:', error);
          throw error;
      }
  }
}


useEffect(() => {
  (async () => {
    try {
      if (testStore.currentSelectedIssue?.id) {
        resolveIssue(testStore.currentSelectedIssue?.id);
      }
    }
    catch (error) {
      console.error("Error resolving issue:", error);
    }
  })()
  
}, [testStore.toggleIssueResolution])

  
  const updateOrganizationUrl = async (url) => {
   

  
    // await doesSessionExist()
    const token = null
    const payload = await Session.getAccessTokenPayloadSecurely();
    const orgId = payload?.user_info?.organisation_id;
  
  
    try {
      const response = await apiClient.post(
        `/api/v1/accounts/organisation-url`,
        {
            organisationId: String(orgId),
            url: String(url) 
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response?.status === 200) {
        testStore.setOrganizationUrl(response?.data?.url);
        return response?.data;
      }
      else{
        testStore.setOrganizationUrl("");
        return null;
      }
    } catch (error) {
      console.error('Error updating organization URL:', error);
      
    }
  };
  
  const fetchWebAnalysisReport = async (url) => {
    
    if (!url) {
      return null;
    }
  
    
  
    // await doesSessionExist()
    
   
    
    try {
      const queryParams = new URLSearchParams({
        url: String(url)
      }).toString();
      
      const response = await fetch(
        `${process.env.REACT_APP_WEBSITE_ANALYTICS_BASE_URL}/web-analysis/last-evaluated-report?${queryParams}`
      );
  
     
  
      if (!response?.ok) {
        switch (response?.status) {
          case 404:      
            return null;
          case 400:    
            return null;
          case 429:
            return null;
          default:
            return null;
        }
      }
      
      const data = await response.json();
     
      if (data) {
        return data;
      } else {
        
        return null;
      }
    } catch (error) {
      console.error('Error fetching web analysis report:', error);
      if (error instanceof Response) {
        console.error('Response status:', error.status);
        const errorData = await error.json();
        console.error('Response data:', errorData);
      }
      return null;
    }
  };
  
  const initiateWebAnalysis = async (url) => {
    if (!url) {
      return null;
    }
  
    
    const payload = await Session.getAccessTokenPayloadSecurely();
    const orgId = payload?.user_info?.organisation_id;
    const userId = await Session.getUserId();
  
    try {
      
      const initialResponse = await axios.get(`${process.env.REACT_APP_WEBSITE_ANALYTICS_BASE_URL}/web-analysis`, {
        params: {
          url: String(url),
          autoflowUserId: String(userId),
          autoflowOrganisationId: String(orgId)
        }
      });
  
      if (!initialResponse.data?.uniqueTaskId) {
        return null;
      }
  
      const taskId = initialResponse.data.uniqueTaskId;
      
  
      // Start polling for results
      return new Promise((resolve, reject) => {
        const pollInterval = 5000; // 5 seconds
        let attempts = 0;
        const maxAttempts = 60; // 5 minutes maximum polling time
  
        const checkStatus = async () => {
          try {
            const statusResponse = await axios.get(`${process.env.REACT_APP_WEBSITE_ANALYTICS_BASE_URL}/web-analysis/status`, {
              params: {
                id: taskId
              }
            });
  
         
  
            const { status, task } = statusResponse.data;
            
  
            switch (status) {
              case 'COMPLETED':{
                resolve(task);
                break;
              }
              case 'PENDING_SUMMARY':{
  
                attempts++;
                if (attempts < maxAttempts) {
                  testStore.setScoreCardData(task);
                  testStore.setLoadingTillPending(false);
                  setTimeout(checkStatus, pollInterval);
                } else {
                  // If summary is taking too long, return partial results
                  resolve(task);
                }
                break;
              }
  
              case 'PENDING':{
  
                attempts++;
                if (attempts < maxAttempts) {
                  setTimeout(checkStatus, pollInterval);
                } else {
                  reject(new Error('Analysis timed out after 5 minutes'));
                }
                break;
              }
              case 'FAILED':{
                reject(new Error(`Analysis failed: ${status}`));
                break;
              }
  
              default:{
                reject(new Error(`Unknown status received: ${status}`));
              }
            }
          } catch (error) {
            console.error('Error checking analysis status:', error);
            reject(error);
          }
        };
  
        // Start polling
        checkStatus();
      });
    } catch (error) {
      console.error('Error initiating web analysis:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      throw error;
    }
  };

  async function validateUrl(url, options = {}) {
    const {
        enforceHttps = true,
        timeout = 5000
    } = options;

    

    try {
        // Handle empty or invalid inputs
        if (!url || typeof url !== 'string') {
            return {
                isValid: false,
                url: '',
                error: 'URL is required'
            };
        }

        // Clean and format URL
        let cleanUrl = url.trim();

        // Handle protocol
        if (enforceHttps) {
            // Remove any existing protocol
            cleanUrl = cleanUrl.replace(/^(https?:\/\/|www\.)/i, '');
            // Add https protocol
            cleanUrl = `https://${cleanUrl}`;
        } else if (!cleanUrl.match(/^https?:\/\//i)) {
            // If no protocol specified, add http://
            cleanUrl = `http://${cleanUrl}`;
        }

        // Try HEAD request first
        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), timeout);

            const headResponse = await fetch(cleanUrl, {
                method: 'HEAD',
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            if (headResponse.ok) {
                return {
                    isValid: true,
                    url: cleanUrl
                };
            }
        } catch (headError) {
            // HEAD request failed, try GET request
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), timeout);

            const getResponse = await fetch(cleanUrl, {
                method: 'GET',
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            if (getResponse.ok) {
                return {
                    isValid: true,
                    url: cleanUrl
                };
            }
        }

        // Both requests failed
        return {
            isValid: false,
            url: cleanUrl,
            error: 'URL is not accessible'
        };

    } catch (error) {
        return {
            isValid: false,
            url: url,
            error: error.name === 'AbortError' 
                ? 'Request timed out' 
                : 'URL is invalid or not accessible'
        };
    }
}


  const handleOrgUrlSubmit = async () => {
    try {
    
     if(testStore.editingWebAnalysisUrl){
      testStore.setEditingWebAnalysisUrl(false);
    } 
      testStore.setAnalysisFailed(false);
      setOrgUrlErr("");
      setCheckingUrl(true);
     
      let finalURL = await validateUrl(orgUrl);
    
      setCheckingUrl(false);
  
      if(!finalURL?.isValid){
        setOrgUrlErr(finalURL?.error);
        return;
      }
   
      document.getElementById('my_modal_1').close();
    
      updateOrganizationUrl(finalURL.url);
      testStore.setLoadingTillPending(true);
      testStore.setLoadingScoreCard(true);
   
      let data = null;
      if(!testStore.reanalyzeScoreCard){
       
        data = await fetchWebAnalysisReport(finalURL.url);
        
      }
    
      if(data){
     
        testStore.setScoreCardData(data);
      }
      else{ 
      
        testStore.setScoreCardData(null);
       
        data = await initiateWebAnalysis(finalURL.url);
        testStore.setScoreCardData(data); 
      }
    
      testStore.setLoadingScoreCard(false);
     
      
    } catch (error) {
    
      testStore.setScoreCardData(null);
      console.error("An error occurred:", error.message);
      testStore.setAnalysisFailed(true);
    } 
    finally{
   
      if(testStore.reanalyzeScoreCard){
      
        testStore.setReanalyzeScoreCard(false);
      }
      testStore.setLoadingScoreCard(false);
      testStore.setLoadingTillPending(false);
    }
   
  };

  useEffect(() => {
    (async () => {
      pendingIssuesPageRef.current = 1;
      const data = await fetchIssues({
        workspaceId: selected?.id,
        status: "OPEN",
        pageNumber: pendingIssuesPageRef.current,
      });
      
      const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
      const relevantIssues = validNewIssues.filter(issue =>
        issue.stepId !== "None" && issue.flowId !== "None"
      );
      const transformedIssues = transformIssuesData(relevantIssues);
     
      setPendingIssues(transformedIssues);
      if(transformedIssues.length > 0){
      
        
        setHasMorePendingIssues(true);
      }
      else{
        setHasMorePendingIssues(false);
      }
    })();
  }, [selected]);

  useEffect(() => {
    (async () => {
      resolvedIssuesPageRef.current = 1;
      const data = await fetchIssues({
        workspaceId: selected?.id,
        status: "CLOSED",
        pageNumber: resolvedIssuesPageRef.current,
      });
      const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
      const relevantIssues = validNewIssues.filter(issue =>
       issue.stepId !== "None" && issue.flowId !== "None"
      );
      const transformedIssues = transformIssuesData(relevantIssues);
     
      setResolvedIssues(transformedIssues);
      if(transformedIssues.length > 0){
        
        setHasMoreResolvedIssues(true);
      }
      else{
        setHasMoreResolvedIssues(false);
      }
    })();
  }, [selected]);

  useEffect(() => {
    (async () => {
      allIssuesPageRef.current = 1;
      const data = await fetchIssues({
        workspaceId: selected?.id,
        pageNumber: allIssuesPageRef.current,
      });
      const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
      const relevantIssues = validNewIssues.filter(issue =>
       issue.stepId !== "None" && issue.flowId !== "None"
      );
      const transformedIssues = transformIssuesData(relevantIssues);
      setAllIssues(transformedIssues);
      if(transformedIssues.length > 0){
        
        setHasMoreAllIssues(true);
      }
      else{
        setHasMoreAllIssues(false);
      }
    })();
  }, [selected]);

  const ChartLoadingOverlay = () => (
    <div className="absolute inset-0 bg-Surface-Raised-Primary/80 backdrop-blur-[1px] flex items-center justify-center">
      <span className="loading loading-spinner loading-lg text-text-subtle"></span>
    </div>
  );

  useEffect(() => {
    if(testStore.allHistoryData.length === 0){
      getAllHistory();
    }
    if(testStore.workSpaceArr.length===0){
      getWorkspace();
    }
    if(testStore.filterUserBucketsArr.length===0){
      getFlows();
    }
    if(testStore.scheduleData.length===0){
      getSchedule();
    }
  }, [])

  useEffect(() => {
    const nonObservedHistory = toJS(testStore.allHistoryData);
    setMiniHistory(nonObservedHistory);
  }, [testStore.allHistoryData])

  const handleDropdownOpen = () => {
    const issuesContainer = document.getElementById('recent-issues-table');
    if (issuesContainer) {
      issuesContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const fetchOrganizationUrl = async () => {
    const token = await Session.getAccessToken();
    const payload = await Session.getAccessTokenPayloadSecurely();
    const orgId = payload?.user_info?.organisation_id;
    try {
      const response = await apiClient.get(
        `/api/v1/accounts/organisation-url`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            organisation_id: orgId
          }
        }
      );


      
      
      if (response?.status === 200) {
        testStore.setOrganizationUrl(response?.data?.url);
        return response?.data;
      }
      else{
        testStore.setOrganizationUrl("");
        return null
      }
    } catch (error) {
      console.error('Error fetching organization URL:', error);
      throw error;
    }
  };

  const fetchTeamMembers = async () => {
    try {
        // Verify session exists
        const token = await Session.getAccessToken();
  
        if (!token) {
            throw new Error('Authentication token not available');
        }
  
        const response = await apiClient.get(
            `/api/v1/analytics-dashboard/team-members`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
    
        testStore.setTeamMembers(response?.data);
        // return response?.data;
    } catch (error) {
        if (error.response) {
            console.error('Error fetching team members:', error.response.data);
            throw error.response.data;
        } else {
            console.error('Error:', error);
            throw error;
        }
    }
  };

  useEffect(() => {

    fetchTeamMembers();
  }, []);

  const fetchTimeSavedStats = async ({ fromDate, toDate } = {}) => {
    try {
        // Verify session exists
        const token = await Session.getAccessToken();
  
        if (!token) {
            throw new Error('Authentication token not available');
        }
  
        // Build query parameters
        const queryParams = new URLSearchParams();
        if (fromDate) queryParams.append('from_date', fromDate);
        if (toDate) queryParams.append('to_date', toDate);
  
        const response = await apiClient.get(
            `/api/v1/analytics-dashboard/time-saved-with-date-range?${queryParams}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
  
       
        testStore.setTimeSavedWithDateRange(response?.data);
  
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Error fetching time saved stats:', error.response.data);
            throw error.response.data;
        } else {
            console.error('Error:', error);
            throw error;
        }
    }
  };

  const fetchRunStats = async ({ fromDate, toDate }) => {
    try {
        // Verify session exists
        const token = await Session.getAccessToken();
  
        if (!token) {
            throw new Error('Authentication token not available');
        }
  
        // Build query parameters
        const queryParams = new URLSearchParams();
        if (fromDate) queryParams.append('from_date', String(fromDate));
        if (toDate) queryParams.append('to_date', String(toDate));
  
        const response = await apiClient.get(
            `/api/v1/analytics-dashboard/run-stats?${queryParams}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        
        testStore.setAnalyticsChartData(response?.data);
    
    } catch (error) {
        if (error.response) {
            console.error('Error fetching run stats:', error.response.data);
            throw error.response.data;
        } else {
            console.error('Error:', error);
            throw error;
        }
    }
  };

  useEffect(() => {
 
    (async () => {
      try{  
        testStore.setLoadingTimeAndRunStats(true);
        await fetchRunStats({ fromDate: testStore.analyticsDateRange?.fromDate, toDate: testStore.analyticsDateRange?.toDate })
        await fetchTimeSavedStats({ fromDate: testStore.analyticsDateRange?.fromDate, toDate: testStore.analyticsDateRange?.toDate })
        testStore.setLoadingTimeAndRunStats(false);
      }
      catch(error){
        console.error("Error fetching issues:", error);
      }
    })()
    
  }, [testStore.analyticsDateRange?.fromDate, testStore.analyticsDateRange?.toDate]);

  useEffect(()=>{
    (async ()=>{      
       try {
         if(!testStore.organizationUrl){
           let data = await fetchOrganizationUrl();
          
           testStore.setOrganizationUrl(data?.url);
         }
         
        
         if(!testStore.organizationUrl){
           
          //  testStore.setOrgUrlModalOpen(true);
          document.getElementById('my_modal_1').showModal()
         }
         else{
           if(!testStore.scoreCardData){
           
          //  testStore.setOrgUrlModalOpen(false);
          document.getElementById('my_modal_1').close();
           if(!testStore.loadingScoreCard){
             testStore.setLoadingTillPending(true);
           testStore.setLoadingScoreCard(true);
           let reportData = await fetchWebAnalysisReport(testStore.organizationUrl);
           if(reportData){
             testStore.setScoreCardData(reportData);
           }
           else{
             
             reportData = await initiateWebAnalysis(testStore.organizationUrl);  
             testStore.setScoreCardData(reportData);  
             
           }
           testStore.setLoadingScoreCard(false);
           }
          }
         }
         
       } catch (error) {
         testStore.setScoreCardData(null);
         testStore.setAnalysisFailed(true);
         console.error("An error occurred:", error.message);
       } 
       finally{
         testStore.setLoadingScoreCard(false);   
         testStore.setLoadingTillPending(false);
       }
     
    })()
   },[testStore.flowTourOn])


   const getSchedule = async () => {
    try {
      const token = await Session.getAccessToken();
  
      if (!token) {
        console.error("Your session has expired.");
        return;
      }
  
      try {
        const response = await apiClient.get(`/api/v1/schedule-runs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          const responseData = response.data;
          testStore.setScheduleFilterData(responseData?.schedules);
        } else {
          console.error(response.data?.error || "Failed to fetch schedules.");
        }
      } catch (error) {
        console.error("Error fetching schedule runs:", error);
      }
    } catch (error) {
      console.error("Error during session check or token retrieval:", error);
    }
  };

  const getFlows = async () => {
    try {
      const token = await Session.getAccessToken();
      const userId = await Session.getUserId();

      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const response = await apiClient.get(`/api/v1/flows`, config);

          if (response.status === 200) {
            const responseData = response.data;
            console.log("Response from the server:", responseData);
            console.log("Buckets from the server:", responseData.buckets);
            let newBucketArr = responseData?.buckets?.map((bucket) => ({
              ...bucket,
              type: 'bucket',
              journeys: bucket?.journeys?.map((journey) => ({
                ...journey,
                type: 'journey',
                flows: journey?.flows
                  ?.sort((a, b) => a?.journey_sequence_number - b?.journey_sequence_number)
                  .map((flow) => ({
                    ...flow,
                    type: 'flow',
                  })),
              })),
            }));

            newBucketArr.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            testStore.setUserBuckets(newBucketArr);
            testStore.setFilterUserBucketsArr(newBucketArr);
            console.log(newBucketArr, "new bucket arr");
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      } else {
        console.log("Token is not available");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWorkspace = async () => {
    try {
      const message_id = uuidv4();
      const token = await Session.getAccessToken();
      const userId = await Session.getUserId();
      const accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
      const workspaceId = testStore.currentWorkspaceID !== "" ? testStore.currentWorkspaceID : userId;
  
      if (token) {
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };
  
        try {
          const response = await apiClient.get(`/api/v1/workspaces`,config);
  
          if (response.status === 200) {
            const workspaceData = toJS(response.data);
            testStore.setCurrentWorkspaceID(workspaceId);
            testStore.setWorkspaceArr(workspaceData);
            testStore.setWorkspacesForIssues(workspaceData);
  
            const workspaceName = testStore.workSpaceArr?.find(
              (work) => work.id === testStore.currentWorkspaceID
            )?.name;
            console.log("Workspace Name:", workspaceName);
          } else {
            console.error("Failed to fetch workspaces:", response.status);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      } else {
        console.log("Token is not available");
      }
    } catch (error) {
      console.error("Error in getWorkspace function:", error);
    }
  };



  const getAllHistory = async () => {
    try {
      const token = await Session.getAccessToken();
      const userId = await Session.getUserId();
  
      if (token) {
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };
  
        try {
          const response = await apiClient.get(`/api/v1/test-runs/history`, config);
  
          if (response.status === 200) {
            const data = response.data;
  
            const transformedBucketRuns = data?.bucketRuns?.map(bucket => ({
              ...bucket,
              type: 'BucketRun',
              title: bucket.bucketName,
              id: bucket.bucketRunId,
              uniformData: bucket.bucketRunId,
            }));
  
            const transformedJourneyRuns = data?.journeyRuns?.map(journey => ({
              ...journey,
              type: 'JourneyRun',
              title: journey.journeyName,
              id: journey.journeyRunId,
              uniformData: journey.journeyRunId,
            }));
  
            testStore.setAllHistoryData([...transformedBucketRuns, ...transformedJourneyRuns]);
          } else {
            console.error('Unexpected response status:', response.status);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      } else {
        console.log("Token is not available");
      }
    } catch (error) {
      console.error("Error fetching buckets with journey runs:", error);
    }
  };
  
  useEffect(() => {
    const getAllEnvs = async () => {
      try {
        const token = await Session.getAccessToken();

        if (!token) {
          console.log("Token is not available");
          return [];
        }

        const response = await apiClient.get(`/api/v1/environments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data || [];
      } catch (error) {
        console.error("Error fetching environments:", error);
        return [];
      }
    };

    const updateEnvs = async () => {
      try {
        let envsArr = await getAllEnvs();

        const sortedEnvironments = [...envsArr]?.sort((a, b) => {
          if (a.is_global && !b.is_global) {
            return -1;
          }
          if (!a.is_global && b.is_global) {
            return 1;
          }
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return nameA.localeCompare(nameB);
        });

        testStore.setEnvironments(sortedEnvironments);
        testStore.setFilterEnvironments(sortedEnvironments);
      } catch (error) {
        console.error("Error updating environments:", error);
      }
    };

    updateEnvs();
  }, []);
 


  // Chart configuration
  const scheduleChartConfig = useMemo(() => ({
    options: {
      chart: {
        height: 350,
        type: 'area',
        animations: { enabled: true, speed: 800 },
        background: 'transparent',
        foreColor: '#BDC2C7',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          autoSelected: 'zoom'
        },
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              toolbar: {
                show: false, // Hide toolbar on smaller screens
              },
            },
          },
        },
      ],
      grid: {
        borderColor: 'rgba(195, 222, 254, 0.20)',
        strokeDashArray: 4,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: timeRange === '24h' ? 'HH:mm' : 'MMM dd',
          datetimeUTC: false
        },
        title: { 
          text: timeRange === '24h' ? 'Time (Hours)' : 'Time (Days)',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        },
        axisBorder: {
          show: true,
          color: '#BDC2C7',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          color: '#BDC2C7',
          height: 6
        },
        crosshairs: {
          show: true,
          stroke: {
            color: '#353E45',
            width: 1,
            dashArray: 0
          }
        }
      },
      yaxis: {
        labels: { show: true },
        title: { 
          text: 'Number of Runs',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        }
      },
      colors: ['#FAAD1B', '#EF4444'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: '#BDC2C7'
        }
      },
      tooltip: {
        x: {
          format: timeRange === '24h' ? 'HH:mm' : 'dd/MM/yy'
        }
      }
    },
    series: [
      {
        name: 'Successful Runs',
        data: testStore.analyticsChartData?.scheduledRuns || []
      }
    ]
  }), [timeRange, testStore.analyticsChartData?.scheduledRuns]);

  const journeyChartConfig = useMemo(() => ({
    options: {
      chart: {
        height: 350,
        type: 'area',
        animations: { enabled: true, speed: 800 },
        background: 'transparent',
        foreColor: '#BDC2C7',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          autoSelected: 'zoom'
        },
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              toolbar: {
                show: false, // Hide toolbar on smaller screens
              },
            },
          },
        },
      ],
      grid: {
        borderColor: 'rgba(195, 222, 254, 0.20)',
        strokeDashArray: 4,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: timeRange === '24h' ? 'HH:mm' : 'MMM dd',
          datetimeUTC: false
        },
        title: { 
          text: timeRange === '24h' ? 'Time (Hours)' : 'Time (Days)',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        },
        axisBorder: {
          show: true,
          color: '#BDC2C7',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          color: '#BDC2C7',
          height: 6
        },
        crosshairs: {
          show: true,
          stroke: {
            color: '#353E45',
            width: 1,
            dashArray: 0
          }
        }
      },
      yaxis: {
        labels: { show: true },
        title: { 
          text: 'Number of Runs',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        }
      },
      colors: ['#FAAD1B', '#EF4444'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: '#BDC2C7'
        }
      },
      tooltip: {
        x: {
          format: timeRange === '24h' ? 'HH:mm' : 'dd/MM/yy'
        }
      }
    },
    series: [
      {
        name: 'Successful Runs',
        data: testStore.analyticsChartData?.totalRuns || []
      }
    ]
  }), [timeRange, testStore.analyticsChartData?.totalRuns]);

  const generateMockData = (range) => {

    let numberOfDays;
    let startDate = new Date();
    
    switch(range) {
      case '24h':{
       
        
          const formattedEndDate = startDate.toISOString().split('T')[0];
         
          numberOfDays = 1;
          startDate.setDate(startDate.getDate() - numberOfDays);
          const formattedStartDate = startDate.toISOString().split('T')[0];
        
          testStore.setAnalyticsDateRange(formattedStartDate, formattedEndDate);
        }
        break;

      case '7d':{
        const formattedEndDate = startDate.toISOString().split('T')[0];
       
        numberOfDays = 7;
        startDate.setDate(startDate.getDate() - numberOfDays);
        const formattedStartDate = startDate.toISOString().split('T')[0];
      
        testStore.setAnalyticsDateRange(formattedStartDate, formattedEndDate);
        break;
      }
        
      case '30d':{
        const formattedEndDate = startDate.toISOString().split('T')[0];
       
        numberOfDays = 30;
        startDate.setDate(startDate.getDate() - numberOfDays);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        
        testStore.setAnalyticsDateRange(formattedStartDate, formattedEndDate);
        
        break;
      }

      case 'custom':
        if (!customDateRange.startDate || !customDateRange.endDate) return;
        
        testStore.setAnalyticsDateRange(customDateRange?.startDate?.toISOString()?.split('T')[0], customDateRange?.endDate?.toISOString()?.split('T')[0]);
        break;

      default:
        const formattedEndDate = startDate.toISOString().split('T')[0];
       
        numberOfDays = 30;
        startDate.setDate(startDate.getDate() - numberOfDays);
        const formattedStartDate = startDate.toISOString().split('T')[0];
       
        testStore.setAnalyticsDateRange(formattedStartDate, formattedEndDate);
       
        break;
    }

   
    
  };

  useEffect(() => {
    generateMockData(timeRange);
  }, [timeRange, customDateRange]);

 
  

  const handleInfiniteScroll = async () => {
 
    if (issueFilter === "all") {
      if (hasMoreAllIssues) {
      
        allIssuesPageRef.current = allIssuesPageRef.current + 1;
        const data = await fetchIssues({
          workspaceId: selected?.id,
          pageNumber: allIssuesPageRef.current,
        });
        const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
        const relevantIssues = validNewIssues.filter(issue =>
          issue.stepId !== "None" && issue.flowId !== "None"
        );
        const transformedIssues = transformIssuesData(relevantIssues);
        
        if(transformedIssues.length > 0){
          
          setAllIssues((prevIssues) => [...prevIssues, ...transformedIssues]);
          setHasMoreAllIssues(true);
        }
        else{
          setHasMoreAllIssues(false);
        }
      }

    }
    else if (issueFilter === "pending") {
      if (hasMorePendingIssues) {
        pendingIssuesPageRef.current = pendingIssuesPageRef.current + 1;
        const data = await fetchIssues({
          workspaceId: selected?.id,
          status: "OPEN",
          pageNumber: pendingIssuesPageRef.current,
        });
       
        const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
        const relevantIssues = validNewIssues.filter(issue =>
           issue.stepId !== "None" && issue.flowId !== "None"
        );
        const transformedIssues = transformIssuesData(relevantIssues);
      
        if (transformedIssues.length > 0) {

          setPendingIssues((prev) => [...prev, ...transformedIssues]);
          setHasMorePendingIssues(true);
        }
        else {
          setHasMorePendingIssues(false);
        }
      }

    }
    else if (issueFilter === "resolved") {
      if (hasMoreResolvedIssues) {
        resolvedIssuesPageRef.current = resolvedIssuesPageRef.current + 1;
        const data = await fetchIssues({
          workspaceId: selected?.id,
          status: "CLOSED",
          pageNumber: resolvedIssuesPageRef.current,
        });
        const validNewIssues = Array.isArray(data?.issues) ? data?.issues : [];
        const relevantIssues = validNewIssues.filter(issue =>
          issue.stepId !== "None" && issue.flowId !== "None"
        );
        const transformedIssues = transformIssuesData(relevantIssues);
      
        if (transformedIssues.length > 0) {
          setResolvedIssues((prev) => [...prev, ...transformedIssues]);
          setHasMoreResolvedIssues(true);
        }
        else {
          setHasMoreResolvedIssues(false);
        }
      }

    }

  }

  

  const successFailureConfig = useMemo(() => ({
    options: {
      chart: {
        height: 350,
        type: 'area',
        animations: { enabled: true, speed: 800 },
        background: 'transparent',
        foreColor: '#BDC2C7',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          autoSelected: 'zoom'
        },
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              toolbar: {
                show: false, // Hide toolbar on smaller screens
              },
            },
          },
        },
      ],
      grid: {
        borderColor: 'rgba(195, 222, 254, 0.20)',
        strokeDashArray: 4,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: timeRange === '24h' ? 'HH:mm' : 'MMM dd',
          datetimeUTC: false
        },
        title: { 
          text: timeRange === '24h' ? 'Time (Hours)' : 'Time (Days)',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        },
        axisBorder: {
          show: true,
          color: '#BDC2C7',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          color: '#BDC2C7',
          height: 6
        },
        crosshairs: {
          show: true,
          stroke: {
            color: '#353E45',
            width: 1,
            dashArray: 0
          }
        }
      },
      yaxis: {
        labels: { show: true },
        title: { 
          text: 'Number of Runs',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        }
      },
      colors: ['#22C55E', '#EF4444'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: '#BDC2C7'
        }
      },
      tooltip: {
        x: {
          format: timeRange === '24h' ? 'HH:mm' : 'dd/MM/yy'
        }
      }
    },
    series: [
      {
        name: 'Successful Runs',
        data: testStore.analyticsChartData?.successfulRuns || []
      },
      {
        name: 'Failed Runs',
        data: testStore.analyticsChartData?.failedRuns || []
      }
    ]
  }), [timeRange, testStore.analyticsChartData]);

  const localCloudConfig = useMemo(() => ({
    options: {
      chart: {
        height: 350,
        type: 'area',
        animations: { enabled: true, speed: 800 },
        background: 'transparent',
        foreColor: '#BDC2C7',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          autoSelected: 'zoom'
        },
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              toolbar: {
                show: false, // Hide toolbar on smaller screens
              },
            },
          },
        },
      ],
      grid: {
        borderColor: 'rgba(195, 222, 254, 0.20)',
        strokeDashArray: 4,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: timeRange === '24h' ? 'HH:mm' : 'MMM dd',
          datetimeUTC: false
        },
        title: { 
          text: timeRange === '24h' ? 'Time (Hours)' : 'Time (Days)',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        },
        axisBorder: {
          show: true,
          color: '#BDC2C7',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          color: '#BDC2C7',
          height: 6
        },
        crosshairs: {
          show: true,
          stroke: {
            color: '#353E45',
            width: 1,
            dashArray: 0
          }
        }
      },
      yaxis: {
        labels: { show: true },
        title: { 
          text: 'Number of Runs',
          style: {
            fontSize: '12px',
            fontWeight: 500
          }
        }
      },
      colors: ['#E879F9', '#60A5FA'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: '#BDC2C7'
        }
      },
      tooltip: {
        x: {
          format: timeRange === '24h' ? 'HH:mm' : 'dd/MM/yy'
        }
      }
    },
    series: [
      {
        name: 'Local Runs',
        data: testStore.analyticsChartData?.localRuns || []
      },
      {
        name: 'Cloud Runs',
        data: testStore.analyticsChartData?.cloudRuns || []
      }
    ]
  }), [timeRange, testStore.analyticsChartData]);

  
 

  const LoadingSquare = () => (
    <span className="loading loading-ring loading-lg text-brand"></span>
);

const getTimeUnits = (minutes) => {
 
  const minutesInHour = 60;
  const minutesInDay = minutesInHour * 24;
  const minutesInWeek = minutesInDay * 7;
  const minutesInMonth = minutesInDay * 30;
  const minutesInYear = minutesInDay * 365;

  if (minutes < minutesInHour) {
    return {
      value: minutes,
      unit: 'm',
      remainder: null,
      remainderUnit: null
    };
  }

  const hours = Math.floor(minutes / minutesInHour);
  if (hours < 100) {
    return {
      value: hours,
      unit: 'h',
      remainder: minutes % minutesInHour,
      remainderUnit: 'm'
    };
  }

  const days = Math.floor(minutes / minutesInDay);
  if (days < 100) {
    return {
      value: days,
      unit: 'd',
      remainder: Math.floor((minutes % minutesInDay) / minutesInHour),
      remainderUnit: 'h'
    };
  }

  const weeks = Math.floor(minutes / minutesInWeek);
  if (weeks < 100) {
    return {
      value: weeks,
      unit: 'w',
      remainder: Math.floor((minutes % minutesInWeek) / minutesInDay),
      remainderUnit: 'd'
    };
  }

  const months = Math.floor(minutes / minutesInMonth);
  if (months < 100) {
    return {
      value: months,
      unit: 'mo',
      remainder: Math.floor((minutes % minutesInMonth) / minutesInWeek),
      remainderUnit: 'w'
    };
  }

  const years = Math.floor(minutes / minutesInYear);
  return {
    value: years,
    unit: 'y',
    remainder: Math.floor((minutes % minutesInYear) / minutesInMonth),
    remainderUnit: 'mo'
  };
};



const timeUnits = useMemo(() => 
  getTimeUnits(testStore.timeSavedWithDateRange?.timeSaved?.timeSavedInMinutes || 0),
  [testStore.timeSavedWithDateRange?.timeSaved?.timeSavedInMinutes]
);



const calculateChange = (current, previous) => {
  // If previous is 0, and current is greater, return 100% increase
  if (previous === 0 && current > 0) {
    return {
      total: current,                // Add current total
      percentageChange: 100,
      absoluteChange: current,
      trend: 'up'
    };
  }
  
  // If both are 0, return 0% change
  if (previous === 0 && current === 0) {
    return {
      total: 0,                      // Add current total
      percentageChange: 0,
      absoluteChange: 0,
      trend: 'neutral'
    };
  }
  
  const absoluteChange = current - previous;
  const percentageChange = ((current - previous) / previous) * 100;
  
  return {
    percentageChange: Math.round(percentageChange * 10) / 10, // Round to 1 decimal
    absoluteChange,
    trend: percentageChange > 0 ? 'up' : percentageChange < 0 ? 'down' : 'neutral'
  };
};


const calculateMetricsChanges = (data) => {
  if (!data) return {
    timeSaved: {
      percentageChange: 0,
      absoluteChange: 0,
      trend: 'neutral'
    },
    testsCreated: {
      percentageChange: 0,
      absoluteChange: 0,
      trend: 'neutral'
    },
    cloudRuns: {
      percentageChange: 0,
      absoluteChange: 0,
      trend: 'neutral'
    },
  };
  const changes = {
    timeSaved: calculateChange(
      data?.timeSaved?.timeSavedInMinutes,
      data?.timeSaved?.timeSavedInMinutesPrevious
    ),
    testsCreated: calculateChange(
      data?.totalTests?.testsCreated,
      data?.totalTests?.testsCreatedPrevious
    ),
    cloudRuns: calculateChange(
      data?.totalRuns?.testRunsCloud,
      data?.totalRuns?.testRunsCloudPrevious
    ),
    localRuns: calculateChange(
      data?.totalRuns?.testRunsLocal,
      data?.totalRuns?.testRunsLocalPrevious
    ),
    totalRuns: calculateChange(
      (data?.totalRuns?.testRunsCloud + data?.totalRuns?.testRunsLocal),
      (data?.totalRuns?.testRunsCloudPrevious + data?.totalRuns?.testRunsLocalPrevious)
    )
  };
  
  return changes;
};
const changes = useMemo(() => 
  calculateMetricsChanges(testStore.timeSavedWithDateRange), 
  [testStore.timeSavedWithDateRange]
);




const metricsData = useMemo(() => [
  {
    icon: (
      <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
    title: 'Time Saved',
    value: timeUnits.value,
    unit: timeUnits.unit,
    remainder: timeUnits.remainder,
    remainderUnit: timeUnits.remainderUnit,
    valueClassName: 'text-text-accent-green-primary',
    trend: `${changes.timeSaved.trend}`,
    trendClassName: `${changes.timeSaved.trend === 'up'
      ? 'text-text-accent-green-primary'
      : 'text-text-accent-red-primary'}`,
    trendValue: `${changes.timeSaved.trend === 'up' ? '+' : ''} ${changes.timeSaved.percentageChange}%`,
    subtitle: `${changes.timeSaved.trend === 'up' ? '+'  : ''} ${changes.timeSaved.absoluteChange} hrs`
  },
  {
    icon: (
      <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    ),
    title: 'Total Tests',
    value: testStore.timeSavedWithDateRange?.totalTests?.testsCreated ?? 'N/A',
    trend: `${changes.testsCreated.trend}`,
    trendValue: `${changes.testsCreated.trend === 'up' ? '+'  : ''} ${changes.testsCreated.percentageChange}%`,
    trendClassName: `${changes.testsCreated.trend === 'up'
      ? 'text-text-accent-green-primary'
      : 'text-text-accent-red-primary'}`,
    subtitle: `${changes.testsCreated.trend === 'up' ? '+' :  ''} ${changes.testsCreated.absoluteChange} tests`
  },
  {
    icon: (
      <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    ),
    title: 'Total Runs',
    value: (testStore.timeSavedWithDateRange?.totalRuns?.testRunsLocal ?? 0) + (testStore.timeSavedWithDateRange?.totalRuns?.testRunsCloud ?? 0),
    trend: `${changes?.totalRuns?.trend}`,
    trendValue: `${changes?.totalRuns?.trend === 'up' ? '+' : ''} ${changes?.totalRuns?.percentageChange}%`,
    subtitle: `${changes?.totalRuns?.trend === 'up' ? '+' : ''} ${changes?.totalRuns?.absoluteChange} runs`,
    trendClassName: `${changes?.totalRuns?.trend === 'up'
      ? 'text-text-accent-green-primary'
      : 'text-text-accent-red-primary'}`,
  }
], [
  timeUnits,
  changes,
  testStore.timeSavedWithDateRange?.totalTests?.testsCreated,
  testStore.timeSavedWithDateRange?.totalRuns?.testRunsLocal,
  testStore.timeSavedWithDateRange?.totalRuns?.testRunsCloud
]);

// ... rest of the code ...

const metricsDataForCreation = useMemo(() => [
  {
    icon: <TestSuite className="w-4 h-4 text-text-subtlest" />,
    title: 'Test Suites',
    value: totalCounts?.buckets ?? 0,
    subtitle: 'Current Test Suites',
    valueClassName: 'text-text-primary'
  },
  {
    icon: <JorneyIcon className="w-4 h-4 text-text-subtlest" />,
    title: 'Journeys',
    value: totalCounts?.journeys ?? 0,
    subtitle: 'Current Journeys',
    valueClassName: 'text-text-primary'
  },
  {
    icon: <OutlineWaves className="w-4 h-4 text-text-subtlest" />,
    title: 'Flows',
    value: totalCounts?.flows ?? 0,
    subtitle: 'Current Flows',
    valueClassName: 'text-text-primary'
  }
], [totalCounts]);


const WorkspaceIcon = () => (
  <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
    />
  </svg>
);

// Environment icon
const EnvironmentIcon = () => (
  <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
    />
  </svg>
);

// Schedule icon
const ScheduleIcon = () => (
  <svg className="w-4 h-4 text-text-subtlest" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const metricsDataForWorkspace = useMemo(() => [
  {
    icon: <WorkspaceIcon />,
    title: 'Workspaces',
    value: testStore.workSpaceArr?.length ?? 0,
    subtitle: 'Total Workspaces',
    valueClassName: 'text-text-primary'
  },
  {
    icon: <EnvironmentIcon />,
    title: 'Environments', 
    value: testStore.environments?.length ?? 0,
    subtitle: 'Total Environments',
    valueClassName: 'text-text-primary'
  },
  {
    icon: <ScheduleIcon />,
    title: 'Schedules',
    value: testStore.scheduleFilterData?.length ?? 0,
    subtitle: 'Total Schedules', 
    valueClassName: 'text-text-primary'
  }
], [testStore.workSpaceArr?.length, testStore.environments?.length, testStore.scheduleFilterData?.length]);


const scores = useMemo(() => [
  { label: 'Autoflow Score', score: Math.round(Number(testStore.scoreCardData?.report?.autoflowScore) || 0) },
  { label: 'Performance', score: Math.round(Number(testStore.scoreCardData?.report?.scores?.performance) || 0) },
  { label: 'Accessibility', score: Math.round(Number(testStore.scoreCardData?.report?.scores?.accessibility) || 0) },
  { label: 'Best Practices', score: Math.round(Number(testStore.scoreCardData?.report?.scores?.bestPractices) || 0) },
  { label: 'SEO', score: Math.round(Number(testStore.scoreCardData?.report?.scores?.seo) || 0) },
], [testStore.scoreCardData]);



function countTestElements(data) {
  // Input validation
  if (!Array.isArray(data)) {
      throw new Error('Input must be an array');
  }

  try {
      const counts = {
          buckets: data.length,
          journeys: 0,
          flows: 0
      };

      // Count journeys and flows
      data.forEach(bucket => {
          if (!bucket.journeys || !Array.isArray(bucket.journeys)) {
              throw new Error('Invalid bucket structure: missing journeys array');
          }

          counts.journeys += bucket.journeys.length;

          bucket.journeys.forEach(journey => {
              if (!journey.flows || !Array.isArray(journey.flows)) {
                  throw new Error('Invalid journey structure: missing flows array');
              }
              counts.flows += journey.flows.length;
          });
      });

      return counts;
  } catch (error) {
      throw new Error(`Error counting elements: ${error.message}`);
  }
}

  useEffect(() => {
    try {
      const counts = countTestElements(testStore.filterUserBucketsArr);
  
      setTotalCounts(counts);
      
    } catch (error) {
      console.error('Error:', error.message);
    }
  }, [testStore.filterUserBucketsArr]);


  


  const MetricCircle = ({ value, label, color }) => (
    <div className="flex flex-col items-center">
      <div className="w-12 h-12 mb-2">
        {testStore.loadingScoreCard ? (
          <LoadingSquare />
        ) : (
          <CircularProgressbar
            value={value}
            text={`${value}%`}
            styles={buildStyles({
              pathColor: color,
              textColor: color,
              trailColor: '#404040',
              textSize: '22px',
            })}
          />
        )}
      </div>
      <span className="text-sm text-text-subtle">{label}</span>
    </div>
  );


  const getColorByScore = (score) => {
    if (score >= 90) return '#22A06B'; 
    if (score >= 50) return '#FFAF15'; 
    return '#F7522E'; 
  };
  

  




  const workspaceNames = useMemo(() => [
    { 
        id: "",
        name: "All Workspaces",
        heading: "All Workspaces",
    },
    ...testStore?.workspacesForIssues?.map(workspace => ({
        ...workspace,
        heading: workspace.name
    }))
  ], [testStore.workspacesForIssues]);

  const downloadFile = async (url, fileType = 'json') => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
  
      
      switch(fileType.toLowerCase()) {
        case 'json':
          link.download = 'autoflow-accessibility-report.json';
          break;
        case 'html':
          link.download = 'autoflow-html-report.html';
          break;
        case 'csv':
          link.download = 'autoflow-performance-metrics.csv';
          break;
        default:
          link.download = 'report.' + fileType;
      }
  
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error(`Download failed for ${fileType} file:`, error);
      // Handle error - maybe show a notification to user
    }
  };

const skeletonWidths = ['h-4 w-[95%]', 'h-4 w-[88%]', 'h-4 w-[92%]'];


const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_2206_3026)">
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#9BA4AB" />
    </g>
    <defs>
      <clipPath id="clip0_2206_3026">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
  
const ModalHeader = ({ 
  title = 'Editing Step',
  subtitle,
  description,
  icon = <EditIcon />,
  onClose,
  className = ''
}) => {
  return (
    <div className={`flex flex-row items-start gap-2 border-b border-border-primary pb-4 justify-between ${className}`}>
      <div className="flex flex-row items-start gap-2">
        <div className="flex items-start">
          {icon}
        </div>
        <div className="text-surface-accent-brand-overlay-primary flex flex-col items-start justify-start">
          <div className="text-surface-accent-brand-overlay-primary text-base font-secondary text-nowrap font-medium">
            {title}
          </div>
          {subtitle && (
            <div className="text-text-subtlest text-xs font-secondary font-medium">
              {subtitle}
            </div>
          )}
          {description && (
            <div className="flex flex-col">
              <div className="text-text-subtlest text-xs font-secondary font-medium">
                {description}
              </div>
            </div>
          )}
        </div>
      </div>
      <button 
        className="btn btn-sm btn-circle btn-ghost hover:bg-transparent group p-0 flex items-start" 
        onClick={onClose}
      >
        <CloseIcon/>
      </button>
    </div>
  );
};

  

  return (
    <div className="flex flex-col scroll-smooth mt-8 mb-2 sm:mb-3 bg-black pt-5 sm:px-5 md:px-10">
       <OverlayModal
          bgColor='bg-[#121517]'
          modalHeadingData={{
            heading: `Enter Organisation URL`,
            onClickFn: () => {
              document.getElementById('my_modal_1').close();
              setOrgUrlErr("");
              setOrgUrl("");
              setCheckingUrl(false);
              if (testStore.reanalyzeScoreCard) {
                testStore.setReanalyzeScoreCard(false);
              }
              testStore.setEditingWebAnalysisUrl(false);
            },
          }}
          zIndex={"z-[60]"}
        >
          <div className="mt-4 flex flex-col text-primary-text">
            <label className="text-xs text-white">
              Enter Organisation URL
              <TextInputWithState
                id={"Organization URL"}
                placeholder={`${testStore.organizationUrl ? testStore.organizationUrl : "Enter Organisation URL"}`}
                value={orgUrl}
                onChange={(e) => { setOrgUrl(e.target.value); }}

              />
              {orgUrlErr && <span className="text-text-accent-red-primary ">{orgUrlErr}</span>}
            </label>
            <div className="w-full border-t border-border-primary mt-4 mb-6"></div>
            <div className="flex gap-2 justify-end">
              <SecondaryButton
                onClick={() => {
                  document.getElementById('my_modal_1').close()
                  setOrgUrlErr("");
                  setOrgUrl("");
                  setCheckingUrl(false);
                  if (testStore.reanalyzeScoreCard) {
                    testStore.setReanalyzeScoreCard(false);
                  }
                  if (testStore.editingWebAnalysisUrl) {
                    testStore.setEditingWebAnalysisUrl(false);
                    testStore.setExpandedScoreCard(true);
                  }
                }}
                slim
                textClass='text-secondary-yellow-primary'
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleOrgUrlSubmit}
                textClass="text-selected-shadow"
                bgColorClass="bg-brand"
                type='submit'
                slim
              >
                {checkingUrl ? <span className="loading loading-spinner loading-sm"></span> : "Submit"}
              </PrimaryButton>
            </div>
          </div>
        </OverlayModal>
      {
        testStore.expandedScoreCard ?
        <div className={`absolute inset-0 w-full z-[9999] flex justify-center items-center `}>
          <div className="p-1 w-full sm:p-6 modal-box sm:w-3/5 sm:max-w-2xl rounded bg-[#121517] border border-border-primary transform-none  ">
          <ModalHeader
            title="Site Insights"
            subtitle={
              <div className="flex items-center gap-2">
                <span>{testStore.scoreCardData?.url}</span>
                  <CustomTooltip tooltipContent="Edit" position="right">
                  <button 
                    onClick={() => { 
                      setOrgUrl(testStore.organizationUrl); 
                      testStore.setExpandedScoreCard(false); 
                      // testStore.setOrgUrlModalOpen(true);
                      document.getElementById('my_modal_1').showModal() 
                      testStore.setEditingWebAnalysisUrl(true);
                    }}
                    className="p-1 hover:bg-surface-secondary rounded-md transition-colors duration-200"
                  >
                    <FigmaEditIcon className="w-4 h-4"/>
                  </button>
                </CustomTooltip>
              </div>
            }
            onClose={() => { testStore.setExpandedScoreCard(false); }}
            icon={<Report className="w-6 h-6 text-brand" />}
          />
          <div className=" flex flex-col text-primary-text h-[35vh] overflow-auto pr-2 my-1">
          <div className="flex items-center justify-between gap-2 mb-4 mt-3 text-sm text-primary-text">
            <div className = "flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">

                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
              </svg>
              <span>
                Report from {new Date(testStore.scoreCardData?.evaluated_at || testStore.scoreCardData?.updated_at).toLocaleDateString('en-US', { 
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
              </div>
              {(Date.now() - new Date(testStore.scoreCardData?.evaluated_at).getTime()) > (24 * 60 * 60 * 1000) && (
                <div className="text-xs text-text-accent-red-primary">
                    This report appears to be outdated. Click on the reanalyze button to generate a fresh report.
                </div>
              )}
              </div>
             
               
              <CustomTooltip tooltipContent="Reanalyze">
                <button 
                  onClick={() => { 
                  
                    setOrgUrl(testStore.organizationUrl); 
                    testStore.setExpandedScoreCard(false); 
                    testStore.setReanalyzeScoreCard(true); 
                    handleOrgUrlSubmit();
                  }}
                  className="p-1 hover:bg-surface-secondary rounded-md transition-colors duration-200"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-brand">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                </button>
              </CustomTooltip>
            </div>
         
            <div className="flex flex-col h-full p-2">
     
          <div className="flex justify-center mb-4">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16"> 
                <CircularProgressbar
                  value={scores[0]?.score}
                  text={`${scores[0]?.score}`}
                  styles={buildStyles({
                    textSize: '28px',
                    pathColor: getColorByScore(scores[0].score),
                    textColor: getColorByScore(scores[0].score),
                    trailColor: '#404040',
                  })}
                />
              </div>
              <div className="text-sm font-medium text-brand mt-2">
                {scores[0].label}
              </div>
            </div>
          </div>

         
          <div className="grid grid-cols-4 gap-4 mb-4 border border-border-primary rounded p-4">
            {scores.slice(1).map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="w-12 h-12">
                  <CircularProgressbar
                    value={item?.score}
                    text={`${item?.score}`}
                    styles={buildStyles({
                      textSize: '28px',
                      pathColor: getColorByScore(item.score),
                      textColor: getColorByScore(item.score),
                      trailColor: '#404040',
                    })}
                  />
                </div>
                <div className="text-xs text-primary-text mt-2 text-center">
                  {item?.label}
                </div>
              </div>
            ))}
          </div>
        </div>
                <div className="relative mb-6">
                  <h3 className="text-white font-medium mb-2 text-base">{ testStore.loadingScoreCard ? "Generating Summary..." : "Summary By Autoflow"}</h3>
                  <div
                    className="relative flex flex-col items-end"

                  >
                    {
                      testStore.loadingScoreCard ?
                        (<div className="w-full space-y-3 p-4 rounded border border-border-primary bg-Surface-Raised-Primary">
                          {skeletonWidths.map((size, index) => (
                            <TextSkeleton
                              key={index}
                              bgColor="bg-alpha-600"
                              rounded="rounded"
                              className={size}
                            />
                          ))}

                        </div>)
                        :
                        <div className={`text-sm text-primary-text/80 p-4 rounded border border-border-primary  bg-Surface-Raised-Primary 
                          ${isExpanded ? '' : 'line-clamp-3'}
                          transition-all duration-200 ease-in-out
                          ${!isExpanded && 'hover:bg-Surface-Raised-Primary/80'}`}
                        >
                          {testStore.scoreCardData?.report?.automindData?.autoflowSummary
                            ?
                            testStore.scoreCardData.report.automindData.autoflowSummary.split("\n").map((line, index) => (
                              <p key={index}>
                                {line}
                              </p>
                            ))
                            :
                            'No summary available.'
                          }
                        </div>

                    }
                       
                {!isExpanded && (
                  <div className="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-Surface-Raised-Primary to-transparent pointer-events-none" />
                )}
               
               {!isExpanded && !testStore.loadingScoreCard && (
                  <>
                    <div className="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-Surface-Raised-Primary to-transparent pointer-events-none" />
                    <button
                      onClick={() => setIsExpanded(true)}
                      className="absolute bottom-0 right-0 px-3 py-1 text-xs font-medium 
                        text-brand 
                        bg-surface-accent-brand-primary hover:bg-surface-accent-brand-primary/80
                        rounded-tl border-t border-x border-border-primary/20
                        transition-all duration-200 flex items-center gap-1"
                    >
                      Read More
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </button>
                  </>
                )}
                {isExpanded && !testStore.loadingScoreCard && (
                  <button
                    onClick={() => setIsExpanded(false)}
                    className="mt-2 text-xs font-medium
                      text-brand 
                      bg-surface-accent-brand-primary hover:bg-surface-accent-brand-primary/80
                      px-3 py-1 rounded border border-border-primary/20
                      transition-all duration-200 flex items-center gap-1"
                  >
                    Show Less
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                  </button>
                )}
              </div>
            </div>

         
          
          </div>
          {/* <button 
              className="w-full py-2 px-4 bg-surface-accent-brand-primary hover:bg-surface-accent-brand-primary/80 
                      text-brand rounded transition-colors duration-200 text-sm font-medium
                      border border-border-primary/20 mb-4"
              onClick={() => {
                navigate(history);
                ipcRenderer.send('load-journey-report', testStore.scoreCardData?.reportUrls?.lightHouseReportHTML);
              }}
            >
              View Full Report
            </button> */}

           
            <div className="grid grid-cols-3 gap-3">
              <button className="py-2 px-4 bg-surface-secondary hover:bg-surface-secondary/80 
                            text-primary-text rounded transition-colors duration-200 text-xs
                            border border-border-primary flex items-center justify-center w-full gap-1"
                            onClick={() => downloadFile(testStore.scoreCardData?.reportUrls?.accessibilityIssuesJson, 'json')}>
                 <Download /> Accessibility Report
              </button>
              <button className="py-2 px-4 bg-surface-secondary hover:bg-surface-secondary/80 
                            text-primary-text rounded transition-colors duration-200 text-xs
                            border border-border-primary flex items-center justify-center w-full gap-1" onClick={() => downloadFile(testStore.scoreCardData?.reportUrls?.lightHouseReportHTML, 'html')}>
                 <Download />  HTML Report
              </button>
              <button className="py-2 px-4 bg-surface-secondary hover:bg-surface-secondary/80 
                            text-primary-text rounded transition-colors duration-200 text-xs
                            border border-border-primary flex items-center justify-center w-full gap-1" onClick={() => downloadFile(testStore.scoreCardData?.reportUrls?.lighthouseCsvReportUrl, 'csv')}>
                 <Download />  CSV Report
              </button>
            </div>
          </div>
        </div>:null
      } 
      {/* Header */}
      <div className="flex-1 overflow-y-auto p-1.5 mx-1 sm:mx-0 sm:p-4 z-[800] border border-[#5352529a] bg-black rounded-lg h-auto">
        <div className="h-20 gap-2 sm:h-16 flex flex-col sm:flex-row sm:justify-between justify-self-start  sm:justify-self-auto  items-center">
          <h1 className="text-2xl font-display text-white flex gap-2 self-start sm:self-auto">Analytics
          <span className="underline decoration-2 md:decoration-4 decoration-primary-color underline-offset-4">
             Dashboard
          </span>
             </h1>
          <div className="flex items-center gap-2 bg-Surface-Raised-Primary rounded p-1 self-start sm:self-auto ">
            <div className="flex items-center gap-1 sm:gap-2 bg-Surface-Raised-Primary rounded sm:p-1">
              {['24h', '7d', '30d'].map((range) => (
                <button
                  key={range}
                  className={`px-2 py-1.5 rounded-md text-sm transition-colors ${timeRange === range
                      ? 'bg-alpha-500 text-brand'
                      : 'text-text-subtle hover:text-text-primary'
                    }`}
                  onClick={() => setTimeRange(range)}
                >
                  {range}
                </button>
              ))}

              {/* Custom Date Range Picker */}
              <div className="relative">
                <button
                  className={`px-2 py-1.5 rounded-md text-sm transition-colors ${timeRange === 'custom'
                      ? 'bg-alpha-500 text-brand'
                      : 'text-text-subtle hover:text-text-primary'
                    }`}
                    onClick={() => {
                      setTimeRange('custom');
                      setIsCalendarOpen(true);
                    }}
                >
                  Custom
                </button>

                {(timeRange === 'custom' && isCalendarOpen) && (
                  <div className="absolute top-full -left-44 mt-2 bg-surface-primary rounded-lg p-2 shadow-lg z-10">
                    <DatePicker
                      inline
                      closeOnScroll={(e) => e.target === document}
                      selectsRange={true}
                      startDate={customDateRange.startDate}
                      endDate={customDateRange.endDate}
                      onChange={(update) => {
                        setCustomDateRange({
                          startDate: update[0],
                          endDate: update[1]
                        });
                      }}
                      onClickOutside={() => setIsCalendarOpen(false)} 
                      className="bg-surface-primary text-text-primary border border-border-primary rounded p-1"
                      dateFormat="MMM dd, yyyy"
                      maxDate={new Date()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* stat cards */}
        <div className="flex flex-col md:flex-row sm:gap-4 gap-2 sm:mt-5 mt-3.5 md:mt-0">
          <div className="w-full md:flex-1">
            <div className="bg-Surface-Raised-Primary rounded-lg p-4 border border-border-primary relative  hover:border-surface-accent-brand-overlay-primary transition-colors h-full">
              <div className={`${(!testStore.scoreCardData || !testStore.organizationUrl) && !testStore.loadingScoreCard ? 'opacity-10' : ''}`}>
                <div className="flex items-center justify-between gap-2 mb-2 pb-2 border-b border-border-primary">
                  <div className="flex items-center gap-2">
                    <svg
                      className="w-4 h-4 text-text-subtlest"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                    {testStore.loadingScoreCard ? <span className="loading loading-dots loading-sm text-brand"></span> : <span className="text-text-subtle text-sm">{testStore.organizationUrl}</span>}
                  </div>
                  {
                    !testStore.loadingScoreCard && (
                      <AlternateButton
                        variant="brand"
                        size="md"
                        onClick={() => testStore.setExpandedScoreCard(true)}
                      >
                        View Details
                      </AlternateButton>
                    )
                  }
                </div>
                {/* Metrics Grid */}
                <div className="grid sm:grid-rows-none grid-rows-3 grid-flow-col sm:grid-cols-5 gap-4">
                  {scores.map((metric, index) => (
                    <MetricCircle
                      key={index}
                      value={metric.score}
                      label={metric.label}
                      color={getColorByScore(metric.score)}
                    />
                  ))}
                </div>
              </div>
              {(!testStore.scoreCardData || !testStore.organizationUrl) && !testStore.loadingScoreCard && (
                <div className="absolute inset-0 flex items-center justify-center bg-surface-primary/90 backdrop-blur-[2px] rounded-lg">
                  <div className="flex flex-col items-center justify-center gap-2 p-4 text-center">
                    <div className="relative w-10 h-10">
                      {/* Animated circular progress background */}
                      <div className="absolute inset-0 rounded-full border-2 border-dashed border-text-subtlest/20 "></div>
                      {/* Center icon */}
                      <div className="absolute inset-0 flex items-center justify-center">
                        <svg
                          className="w-4 h-4 text-text-subtlest"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="flex flex-col items-center gap-1">
                    <AlternateButton
                        icon={testStore.analysisFailed ? Error : "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"}
                        onClick={() => {document.getElementById('my_modal_1').showModal()}}
                        variant={testStore.analysisFailed ? "red" : "brand"}
                        size="md"
                        className="flex items-center gap-2"
                      >
                        {testStore.analysisFailed ? "Retry Analysis" : "Fetch Analysis"}
                      </AlternateButton>
                      {testStore.analysisFailed ? <div className="text-xs text-text-accent-red-primary ">Url not supported. Please try a different one.</div> : null}
                    </div>
                    <p className="text-text-subtle text-xs">
                      Fetch the analysis to view performance metrics, accessibility scores, and best practices for your website.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:flex-1">
            <MetricsCard
              metrics={metricsData}
              cardClassName="h-full"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row sm:gap-4 gap-2 sm:mt-4 mt-2">
          <div className="w-full md:flex-1">
            <MetricsCard
              metrics={metricsDataForWorkspace}
              cardClassName="h-full"
            />
          </div>
          <div className="w-full md:flex-1">
            <MetricsCard
              metrics={metricsDataForCreation}
              cardClassName="h-full"
            />
          </div>
        </div>
        
        <div className="flex flex-col h-[1600px] md:flex-row md:h-[800px] sm:gap-4 gap-2 min-h-0 mt-2 sm:mt-4">
          {/* Column First (50%) */}
          <div className="flex-1 flex flex-col sm:gap-4 gap-2 min-h-0">
            {/* Row First (75%) */}
            <div className="flex-1 flex gap-4 min-h-0">
             
              {[
                { title: "Journey Runs", config: journeyChartConfig }
              ].map((chart, index) => (
                <div key={index} className="relative flex-1 bg-Surface-Raised-Primary rounded-lg p-2 sm:p-4 shadow flex flex-col  border border-border-primary hover:border-surface-accent-brand-overlay-primary transition-colors">
                  <h3 className="text-text-subtle font-medium mb-2">{chart.title}</h3>
                  <div className="flex-1 min-h-0 w-[80%] sm:w-[90%] md:w-[95%] xl:w-full">
                    <Chart
                      options={chart.config.options}
                      series={chart.config.series}
                      type="area"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  {/* {testStore.loadingTimeAndRunStats && (
                    <ChartLoadingOverlay />
                  )} */}
                </div>
              ))}
            </div>
            <div className="flex-1 flex gap-4 min-h-0">
              {[
                { title: "Local/Cloud Runs", config: localCloudConfig },
              ].map((chart, index) => (
                <div key={index} className="relative flex-1 bg-Surface-Raised-Primary rounded-lg p-2 sm:p-4 shadow flex flex-col  border border-border-primary hover:border-surface-accent-brand-overlay-primary transition-colors">
                  <h3 className="text-text-subtle font-medium mb-2">{chart.title}</h3>
                  <div className="flex-1 min-h-0 w-[80%] sm:w-[90%] md:w-[95%] xl:w-full">
                    <Chart
                      options={chart.config.options}
                      series={chart.config.series}
                      type="area"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  {/* {testStore.loadingTimeAndRunStats && (
                    <ChartLoadingOverlay />
                  )} */}
                </div>
              ))}
            </div>

          </div>
          <div className="flex-1 flex flex-col sm:gap-4 gap-2 min-h-0">
            <div className="flex-1 flex gap-4 min-h-0">

              {[
                { title: "Successful/Failed Runs", config: successFailureConfig },
              ].map((chart, index) => (
                <div key={index} className="relative flex-1 bg-Surface-Raised-Primary rounded-lg p-2 sm:p-4 shadow flex flex-col  border border-border-primary hover:border-surface-accent-brand-overlay-primary transition-colors">
                  <h3 className="text-text-subtle font-medium mb-2">{chart.title}</h3>
                  <div className="flex-1 min-h-0 w-[80%] sm:w-[90%] md:w-[95%] xl:w-full">
                    <Chart
                      options={chart.config.options}
                      series={chart.config.series}
                      type="area"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  {/* {testStore.loadingTimeAndRunStats && (
                    <ChartLoadingOverlay />
                  )} */}
                </div>
              ))}
            </div>
            <div className="flex-1 flex gap-4 min-h-0">
              {[
                { title: "Schedule Runs", config: scheduleChartConfig }
              ].map((chart, index) => (
                <div key={index} className="relative flex-1 bg-Surface-Raised-Primary rounded-lg p-2 sm:p-4 shadow flex flex-col  border border-border-primary hover:border-surface-accent-brand-overlay-primary transition-colors">
                  <h3 className="text-text-subtle font-medium mb-2">{chart.title}</h3>
                  <div className="flex-1 min-h-0 w-[80%] sm:w-[90%] md:w-[95%] xl:w-full">
                    <Chart
                      options={chart.config.options}
                      series={chart.config.series}
                      type="area"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  {/* {testStore.loadingTimeAndRunStats && (
                    <ChartLoadingOverlay />
                  )} */}
                </div>
              ))}
            </div>
        
          </div>
         
        </div>
        <div className="flex flex-col lg:flex-row w-full sm:mt-4 mt-2 sm:gap-4 gap-2 md:h-[800px] ">
          {/* Left Column */}
          <div className="flex-1 flex flex-col sm:gap-4 gap-2">
            <div className="h-[calc(50%-0.5rem)]">
              <MiniHistoryTable recentHistory={miniHistory} />
            </div>
            <div className="h-[calc(50%-0.5rem)]">
              <WorkspaceMembers />
            </div>
          </div>
          {/* Right Column */}
          <div className="flex-1">
            <div className=" h-full bg-Surface-Raised-Primary rounded-lg p-2 lg:p-4 shadow flex flex-col border border-border-primary hover:border-surface-accent-brand-overlay-primary transition-colors">
              <div className="flex flex-col gap-2 sm:flex-row justify-between items-end mb-4 self-start sm:self-auto ">
                <div className="flex flex-col gap-2 self-start sm:self-auto">
                  <h3 className="text-text-subtle font-medium">Recent Issues</h3>
                  {/*workspaces*/}
                  <div className="flex flex-col items-start gap-2">
                    <span className="text-text-subtlest text-xs font-medium">In Workspace</span>
                    <div onClick={handleDropdownOpen}>
                    <Dropdown
                      menuName="Select Workspace"
                      items={workspaceNames} 
                      selected={selected}
                      changeSelected={setSelected}
                      showSubtext={false} // Set to false for basicItems
                      isEnabled={true}
                       emptyListPlaceHolder="No workspaces available"
                      enableSearch={true}
                 
                      setBackGroundColor="bg-surface-primary"
                      
                    />
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 p-1 bg-surface-primary rounded-lg w-fit">
                  <button
                    onClick={() => setIssueFilter('all')}
                    className={`px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200 ${issueFilter === 'all'
                      ? 'bg-surface-accent-brand-primary text-brand'
                      : 'text-text-subtle hover:text-text-primary'
                      }`}
                  >
                    All Issues
                  </button>

                  <button
                    onClick={() => setIssueFilter('pending')}
                    className={`px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200 ${issueFilter === 'pending'
                      ? 'bg-surface-accent-red-primary text-text-accent-red-primary'
                      : 'text-text-subtle hover:text-text-primary'
                      }`}
                  >
                    Pending
                  </button>
                  <button
                    onClick={() => setIssueFilter('resolved')}
                    className={`px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200 ${issueFilter === 'resolved'
                      ? 'bg-surface-accent-green-primary text-text-accent-green-primary'
                      : 'text-text-subtle hover:text-text-primary'
                      }`}
                  >
                    Resolved
                  </button>
                </div>
              </div>

             
              <div className="flex-1 overflow-y-auto min-h-0" id="recent-issues-table">
                 
                {issueFilter === "pending" ? (pendingIssues?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={pendingIssues?.length}
                    next={handleInfiniteScroll}
                    hasMore={hasMorePendingIssues}
                    loader={<div className="h-full flex flex-col items-center justify-center">
                      <span className="loading loading-spinner loading-lg text-primary-text"></span>
                    </div>}
                    scrollableTarget="recent-issues-table"
                  >

                    {renderAnalyticsItems(pendingIssues, "confirmation_modal_analytics")}

                  </InfiniteScroll>
                ) :
                  (
                    <div className="h-full flex flex-col items-center justify-center text-center">
                      <div className="w-16 h-16 mb-4 rounded-full bg-surface-accent-brand-primary/20 flex items-center justify-center">
                        <svg
                          className="w-8 h-8 text-brand"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>


                      <h4 className="text-lg font-medium text-text-primary mb-2">
                        No Issues Found
                      </h4>
                      <p className="text-text-subtle max-w-sm">
                         No pending issues found. Your tests are running smoothly!
                      </p>
                    </div>
                  )
                ) : issueFilter === "resolved" ? (resolvedIssues?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={resolvedIssues?.length}
                    next={handleInfiniteScroll}
                    hasMore={hasMoreResolvedIssues}
                    loader={<div className="h-full flex flex-col items-center justify-center">
                      <span className="loading loading-spinner loading-lg text-primary-text"></span>
                    </div>}
                    scrollableTarget="recent-issues-table"
                  >

                    {renderAnalyticsItems(resolvedIssues, "confirmation_modal_analytics")}

                  </InfiniteScroll>
                ) :
                  (
                    <div className="h-full flex flex-col items-center justify-center text-center">
                      <div className="w-16 h-16 mb-4 rounded-full bg-surface-accent-brand-primary/20 flex items-center justify-center">
                        <svg
                          className="w-8 h-8 text-brand"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>


                      <h4 className="text-lg font-medium text-text-primary mb-2">
                        No Issues Found
                      </h4>
                      <p className="text-text-subtle max-w-sm">
                        No resolved issues yet. Issues marked as resolved will appear here.
                      </p>
                    </div>
                  )
                ) : (allIssues?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={allIssues?.length}
                    next={handleInfiniteScroll}
                    hasMore={hasMoreAllIssues}
                    loader={<div className="h-full flex flex-col items-center justify-center">
                      <span className="loading loading-spinner loading-lg text-primary-text"></span>
                    </div>}
                    scrollableTarget="recent-issues-table"
                  >

                    {renderAnalyticsItems(allIssues, "confirmation_modal_analytics")}

                  </InfiniteScroll>
                ) :
                  (
                    <div className="h-full flex flex-col items-center justify-center text-center">
                      <div className="w-16 h-16 mb-4 rounded-full bg-surface-accent-brand-primary/20 flex items-center justify-center">
                        <svg
                          className="w-8 h-8 text-brand"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>


                      <h4 className="text-lg font-medium text-text-primary mb-2">
                        No Issues Found
                      </h4>
                      <p className="text-text-subtle max-w-sm">
                         No issues found for the selected workspace. Issues will appear here when tests encounter problems.
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
          modalId='confirmation_modal_analytics'
          closeModal={() => { document.getElementById("confirmation_modal_analytics").close() }}
          heading='Mark As Resolved'
          message='Are you sure you want to mark this issue as resolved?'
          onConfirm={() => {
            
           
            if(issueFilter === "pending"){
              setPendingIssues((prev) =>
                prev
                  .map((issue) =>
                    issue.id === testStore.currentSelectedIssue?.id
                      ? { ...issue, issueStatus: "CLOSED" }
                      : issue
                  )
                  .filter(issue => issue.issueStatus !== "CLOSED")
              );
            }
            else if(issueFilter === "all"){
              setAllIssues((prev) =>
                prev.map((issue) =>
                  issue.id === testStore.currentSelectedIssue?.id
                    ? { ...issue, issueStatus: "CLOSED" }
                    : issue
                )
              );

            }
  
            testStore.setPrimaryRunItems(
              testStore.primaryRunItems.map((item) => {
                if (item.runId === testStore.currentSelectedIssue?.originalData?.journeyRunId) {
               
                  item.secondaryRunItems = item.secondaryRunItems.map((secondaryItem) => {
                    if (secondaryItem.status !== "PASSED") {
                      return {
                        ...secondaryItem,
                        lastRanStep: {
                          ...secondaryItem.lastRanStep,
                          status: "PASSED",
                        },
                      };
                    }
                    return secondaryItem;
                  });
                }
                return item;
              })
            );
            
           
            testStore.setToggleIssueResolution();
            document.getElementById("confirmation_modal_analytics").close()
          }}
        />
    </div>
  );
};

export default observer(AnalyticsWrapper);