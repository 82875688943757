const CustomTooltip = ({ 
    children, // The element that triggers the tooltip
    tooltipContent, // The content to show in the tooltip
    position = "left", // Tooltip position: "left", "right", "top", "bottom"
    className = "", // Additional classes for the tooltip
    showOnHover = true, // Whether to show on hover (group) or controlled externally
    visible = false // For controlled visibility if not using hover
  }) => {
    const positionClasses = {
      left: "right-full mr-2 top-1/2 -translate-y-1/2",
      right: "left-full ml-2 top-1/2 -translate-y-1/2",
      top: "bottom-full mb-2 left-1/2 -translate-x-1/2",
      bottom: "top-full mt-2 left-1/2 -translate-x-1/2"
    };
  
    const containerClass = showOnHover ? "group" : "";
    const visibilityClass = showOnHover 
      ? "invisible group-hover:visible opacity-0 group-hover:opacity-100" 
      : visible ? "visible opacity-100" : "invisible opacity-0";
  
    return (
      <div className={`relative ${containerClass}`}>
        {children}
        <div className={`
          absolute ${positionClasses[position]} ${visibilityClass}
          bg-primary-color-dark  backdrop-blur-sm
          text-xs text-primary-text
          px-3 py-2 rounded-md whitespace-nowrap
          shadow-lg border border-border-primary/20
          transition-all duration-200 ease-in-out
          z-[9999]
          ${className}
        `}>
          {tooltipContent}
        </div>
      </div>
    );
  };
  
  export default CustomTooltip;