import React from 'react'
import ModalHeading from './ModalHeading'


const OverlayModal = (props) => {
    return (

        <dialog id="my_modal_1" className="modal">


            <div className="absolute inset-0 w-full z-50 flex justify-center items-center">
                <div className={`sm:p-6 p-2 modal-box sm:w-3/5  sm:max-w-2xl  rounded ${props?.bgColor?props?.bgColor:'bg-primary-color-dark'}  border border-border-primary transform-none`}>
                    <ModalHeading
                        data={props.modalHeadingData}
                    />
                    {props.children}
                </div>
            </div>
        </dialog>

    )
}

export default OverlayModal