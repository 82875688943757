import { useNavigate } from "react-router-dom";
import { TestSuite, JorneyIcon , ArrowRight } from "../../../../assets/shared_icons";
import Badge from "./Badge";
import { observer } from "mobx-react-lite";
import AlternateButton from "./AlternateButton";
import { toJS } from "mobx";
const MiniHistoryTable = ({ recentHistory = [], maxItems = 10 }) => {
    const navigate = useNavigate();
    
    return (
      <div className="bg-Surface-Raised-Primary rounded-lg border border-border-primary p-2 lg:p-4 hover:border-surface-accent-brand-overlay-primary transition-colors h-full">
        {/* Header */}
        <div className="flex items-center justify-between pb-2 border-b border-border-primary">
          <div className="flex items-center gap-2">
            <svg
              className="w-4 h-4 text-text-subtlest"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="text-text-subtle font-medium">Recent Test Runs</span>
          </div>
          {/* <AlternateButton
            variant="brand"
            size="sm"
            onClick={() => navigate('/history')}
            icon={ArrowRight}
          >
            View All
          </AlternateButton> */}
        </div>
  
        {/* Table */}
        <div className="flex flex-col h-[calc(100%-64px)]"> {/* Adjusted height calculation */}
          {/* Table Header */}
          <div className="grid grid-cols-4 gap-4 px-3 py-2 text-sm text-text-subtle border-b border-border-primary">
            <div>Name</div>
            <div className="">Run Type</div>
            <div className="">Status</div>
            <div className="">Run At</div>
          </div>
  
          {/* Table Body */}
          <div className="flex-1 overflow-y-auto">
            <div className="divide-y divide-border-primary">
              {recentHistory.length > 0 ? (
                recentHistory.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, maxItems).map((item, index) => (
                  <div
                    key={item.id}
                    className="grid grid-cols-4 gap-4 px-3 py-3 text-sm hover:bg-alpha-700 transition-colors"
                  >
                    {/* Name with Icon */}
                    <div className="flex items-center gap-2 overflow-hidden">
                        {item?.type === "BucketRun" ? <TestSuite className="w-4 h-4 text-text-subtle flex-shrink-0" /> : <JorneyIcon className="w-4 h-4 text-text-subtle flex-shrink-0" />}
                      <span className="truncate text-text-primary" title={item?.title}>
                        {item?.title}
                      </span>
                    </div>
  
                   
                    {/* Type */}
                    {/* <div className="">
                      <Badge
                        textClass={item.runType === "cloud" ? "text-sky-300" : "text-indigo-300"}
                        bgColorClass={item.runType === "cloud" ? "bg-sky-900/30" : "bg-indigo-900/30"}
                        size="sm"
                      >
                        {item.runType}
                      </Badge>
                    </div> */}

                    <div className="flex justify-start items-center">
                      <Badge
                        textClass={item.runType === "CLOUD" ? "text-sky-300" : "text-indigo-300"}
                        bgColorClass={item.runType === "CLOUD" ? "bg-sky-900/30" : "bg-indigo-900/30"}
                        size="sm"
                      >
                        {item.runType}
                      </Badge>
                    </div>
  
                    {/* Status */}
                    <div className="flex justify-start items-center">
                      <Badge
                        textClass={item.status === "PASSED" ? "text-text-accent-green-primary" : "text-text-accent-red-primary"}
                        bgColorClass={item.status === "PASSED" ? "bg-surface-accent-green-primary" : "bg-surface-accent-red-primary"}
                        size="sm"
                      >
                        {item.status}
                      </Badge>
                    </div>
  
                    {/* Timestamp */}
                    <div className=" text-text-subtle">
                      {new Date(item.createdAt).toLocaleString()}
                     
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center h-full py-16 px-4 text-center">
                  <svg
                    className="w-16 h-16 text-text-subtlest mb-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                    />
                  </svg>
                  <p className="text-text-subtle font-medium mb-2">No Recent Test Runs</p>
                  <p className="text-text-subtlest text-sm">
                    Test runs will appear here once you start executing tests
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default observer(MiniHistoryTable);