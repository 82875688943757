import React,{ useState } from "react";
import { Clickcursor, InputKeyboard, Navigate, Fileupload, Drag, Mailstep, Imagestep, AssertCheck, OutlineBugReport } from "../../../../assets/shared_icons";


const renderIcon = (data, className) => {
  
    if ((data?.type === "click" && data?.mode !== "printAssertion") || data?.step_data?.playwrightStatement?.includes(".selectOption(") || data?.playwrightStatement?.includes(".selectOption(")) {
      return <Clickcursor className={className} />;
    }
    else if ((data?.type === "input" || data.type === "enter") && data?.mode !== "printAssertion") {
      return <InputKeyboard className={className} />;
    } else if (data?.type === "navigate" && data?.mode !== "printAssertion") {
      return <Navigate className={className} />;
    } else if (data?.type === "file" && data?.mode !== "printAssertion") {
      return <Fileupload className={className} />;
    } else if (data?.type === "drag" || data?.type === "drop") {
      return <Drag className={className} />;
    } else if (data?.type === "email") {
      return <Mailstep className={className} />;
    } else if (data?.type === "img") {
      return <Imagestep className={className} />;
    } else if (data?.mode === "printAssertion") {
      return <AssertCheck className={className} />;
    }
    else{
      return <OutlineBugReport className={className} />;
    }
  };


  export default renderIcon;