import React, { useState } from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaDiscord, FaGithub } from "react-icons/fa";
import { SlSocialLinkedin } from "react-icons/sl";
import {
  containerStyles,
  footerGridStyles,
  logoStyles,
  socialLinkStyles,
  systemStatusStyles,
} from "./appFooterStyles";
import { logo_white } from "../../../assets";
import { useForm } from "react-hook-form";
import { joinWaitList } from "../../../api/services/apiService";
import { Link } from "react-router-dom";

const AppFooter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isWaitlisted, setIsWaitlisted] = useState(false);

  const onSubmit = (data) => {
    if (data.email) {
      let payload = {
        email: data.email,
      };
      joinWaitList(payload)
        .then((res) => {
          if (res) {
            setIsWaitlisted(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <footer>
      <div className={containerStyles.container}>
        <h4
          className={containerStyles.heading}
          style={{ fontFamily: "Plus Jakarta Sans Variable, sans-serif" }}
        >
          Subscribe to our Newsletter
        </h4>
        <p className={containerStyles.paragraph}>
          Recieve monthly updates on new posts and features.
        </p>
        {isWaitlisted ? (
          <p className="mt-8 text-gray-300/90 text-center font-secondary mb-6 text-[10px] md:text-base  px-4 py-1 rounded-xl shadow-[#faad1b_0px_0px_5px] ">
            Congratulations! You're now a part of the AutoFlow Club!
          </p>
        ) : (
          <form
            action=""
            className={containerStyles.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="text"
              placeholder="Email"
              {...register("email", {
                required: "*email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                  message: "*Invalid email address",
                },
              })}
              className={`${containerStyles.input} ${errors?.email ? "border-red-500/50" : ""
                }`}
            />
            {errors?.email && (
              <p className="absolute bottom-10 sm:-bottom-4 left-2 sm:left-1/2 text-red-500 text-[10px]">
                {errors.email?.message}
              </p>
            )}
            <button
              type="submit"
              name="subscribe"
              className={containerStyles.submitButton}
            >
              Subscribe
            </button>
          </form>
        )}
      </div>
      <div className="relative mx-auto max-w-5xl px-4 sm:px-6 pb-12 xl:pb-0">
        <div className={footerGridStyles.grid}>
          <div className="sm:col-span-12">
            <div className="flex h-full flex-col justify-between sm:flex-row lg:flex-col">
              <div className={logoStyles.container}>
                <div className="mb-4 text-center sm:text-left">
                  <div className="inline-flex items-center justify-center rounded-full border border-zinc-500/50 p-2 outline-none transition hover:scale-110 hover:border-zinc-500/80 cursor-pointer">
                    <img
                      src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflow_512.png?updatedAt=1721131823360"
                      alt="autoflow logo"
                      className={logoStyles.image}
                    />
                    </div>
                  </div>
                <div className={logoStyles.text}>
                  © 2024 Reveedo Technologies Pvt. Ltd. All rights reserved.
                </div>
              </div>

              <div className="flex sm:flex-row flex-col items-center justify-between gap-4 ">
                <ul className="flex items-center">
                  <li>
                    <div className={systemStatusStyles.status}>
                      <div className="mr-2 h-1 w-1 rounded-full bg-green-500 font-display font-normal" />
                      <a target="_blank" href="https://status.autoflow.tools">All systems operational.</a>
                    </div>
                  </li>
                </ul>
                <ul className="flex items-center gap-2">
                  <li>
                    <div className={systemStatusStyles.status}>
                      <Link to={'/terms'}>
                        <p>Terms of Service</p>
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div className={systemStatusStyles.status}>
                      <Link to={'/privacy'}>
                        <p> Privacy Policy</p>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={systemStatusStyles.status}>
                      <Link to={'/return-policy'}>
                        <p> Return Policy</p>
                      </Link>
                    </div>

                  </li>
                </ul>

                <ul className={socialLinkStyles.linkList}>
                  <li>
                    <a
                      className={socialLinkStyles.linkButton}
                      href="https://discord.gg/FHXJRkYqEQ"
                      aria-label="Discord"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaDiscord className={socialLinkStyles.icon} />
                    </a>
                  </li>
                  <li>
                    <a
                      className={socialLinkStyles.linkButton}
                      href="https://twitter.com/AutoFlowLabs"
                      aria-label="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsTwitterX className={socialLinkStyles.icon} />
                    </a>
                  </li>
                  <li>
                    <a
                      className={socialLinkStyles.linkButton}
                      href="https://www.linkedin.com/company/autoflow-official"
                      aria-label="LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SlSocialLinkedin className={socialLinkStyles.icon} />
                    </a>
                  </li>
                  <li>
                    <a
                      className={socialLinkStyles.linkButton}
                      href="https://github.com/AutoFlowLabs/autoflow"
                      aria-label="Github"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGithub className={socialLinkStyles.icon} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
