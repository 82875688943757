import React, { createContext, useContext } from "react";
import { useLocalObservable } from "mobx-react";
import testStore from "../stores/TestStore";

const TestContext = createContext();

const TestProvider = ({ children }) => {
  const store = useLocalObservable(() => testStore);

  return <TestContext.Provider value={store}>{children}</TestContext.Provider>;
};

const useTestStore = () => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error("useTestStore must be used within the TestProvider");
  }
  return context;
};

export { TestProvider, useTestStore };
