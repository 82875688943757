import React, { useState, useEffect,useRef, useCallback, useMemo } from 'react';
import testStore from '../../../../stores/TestStore';
import { Environments, InfoIcon, JorneyIcon } from '../../../../assets/shared_icons';

export const Dropdown = ({ menuName, items, selected, changeSelected, showSubtext, isEnabled, emptyListPlaceHolder, customClasses, enableSearch ,setBackGroundColor, headerButton = null}) => {


 
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);
   
  
    const dropdownRef = useRef(null);
    const observerRef = useRef(null);
    const updatePosition = useCallback(() => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        let top = rect.bottom;
        let left = rect.left;
        let isAbove = false;
  
        
        const dropdownHeight = 160; 
        const spaceBelow = viewportHeight - rect.bottom;
        
        if (spaceBelow < dropdownHeight && rect.top > dropdownHeight) {
          
          top = rect.top - dropdownHeight;
          isAbove = true;
        } else {
          
          top = rect.bottom;
        }
  
        setDropdownPosition({ top, left, isAbove });
      }
    }, []);
    
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
          setSearchQuery("");
          setIsVisible(false);
          setTimeout(() => {
            setIsOpen(false);
          }, 150);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]); 
  
    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };
  
      const observerCallback = (entries) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting && isOpen) {
            if(setIsOpen){
              setIsOpen(false);
            }
            setIsVisible(false);
          }
        });
      };
  
      observerRef.current = new IntersectionObserver(observerCallback, options);
  
      if (dropdownRef.current) {
        observerRef.current.observe(dropdownRef.current);
      }
  
      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, [isOpen]);
    
  
    useEffect(() => {
      updatePosition();
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition, true);
    
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition, true);
      };
    }, [updatePosition]);
  
    useEffect(() => {
      if (isOpen) {
        updatePosition();
       
        const visibilityTimeout = setTimeout(() => setIsVisible(true), 50);
       
        return () => {
        
          clearTimeout(visibilityTimeout);
        };
      } else {
        setIsVisible(false);
      }
    }, [isOpen, updatePosition]);
  
    useEffect(()=>{
        setIsOpen(false);
      
    },[testStore.resetDropdown]);
  
    const handleInputChange = (e) => {
      const newQuery = e.target.value;
      setSearchQuery(newQuery);
      if (newQuery.length > 0 && !isOpen) {
        setIsOpen(true);
      }
    };
  
    const handleInputClick = () => {
      if (searchQuery.length > 0) {
        setSearchQuery('');
        
      }
      if (isOpen) {
        setIsVisible(false);
        setIsOpen(false)
       
      } else {
        setIsOpen(true);
      }
    };
  
    // Filter items based on search query
    const filteredItems = enableSearch ? items.filter(item => 
      item?.heading?.toLowerCase()?.includes(searchQuery.toLowerCase())
    ) : items;
    
    return (
      <div ref={dropdownRef} className={`relative w-full text-left bg-Surface-Raised-Primary  hover:bg-hover-gray  rounded-md   ${!isEnabled ? 'opacity-30' : ''}`}>
        {
          !enableSearch ?
            (
              <button onClick={() => {
                if (isOpen) {
                  setIsVisible(false);
                  setTimeout(() => {
                    setIsOpen(false);
                  }, 150);
                } else {
                  setIsOpen(true);
                }
              }} className={`text-input-border-color-hover focus:border-input-border-color-focused border-none outline-none  focus:bg-Surface-Raised-Primary focus:border focus:border-solid  text-left text-sm  rounded px-1 pl-2  py-1.5 w-full flex justify-between items-center truncate  border ${customClasses} `}>
                <div className='text-linkfont-normal  font-inter'>{selected ? selected?.heading : menuName ? menuName : "Choose an option"}</div>
                <div onClick={handleInputClick}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={`cursor-pointer min-w-4 w-4 h-4 stroke-2 stroke-[#7A858F] transition-all duration-300 ${isOpen ? "rotate-180" : ""}`}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg></div>
              </button>
            ) :
            (
              <div className={`flex gap-1 p-2 text-body-md ${setBackGroundColor ? setBackGroundColor : 'hover:bg-shadow-bg-hover focus:bg-Surface-Raised-Primary  bg-Surface-Raised-Primary'}   items-center placeholder:text-text-subtlest focus:placeholder:text-input-border-color-hover text-input-border-color-hover text-linkfont-normal  font-inter  focus:rounded     z-10  rounded  border  border-border-primary focus-within:border-border-selected `}>
                <input
                  type="text"
                  // value={searchQuery}
                  onChange={handleInputChange}
                 
                  onClick={handleInputClick}
                  value={searchQuery}
                  placeholder={selected ? selected?.heading : menuName ? menuName : "Choose an option"}
                  className={`w-full grow bg-transparent focus:border-none focus:outline-none border-none outline-none ${customClasses}`}
                />
                
                  <svg onClick={handleInputClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={`cursor-pointer min-w-4 w-4 h-4 stroke-2 stroke-[#7A858F] transition-all duration-300 ${isOpen ? "rotate-180" : ""}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                
              </div>
            )
        }
        {isOpen && isEnabled && (
          <div
            style={{
              position: 'fixed',
              top: `${dropdownPosition.isAbove ? dropdownPosition.top - 5 : dropdownPosition.top + 5}px`,
              left: `${dropdownPosition.left}px`,
              width: dropdownRef.current ? `${dropdownRef.current.offsetWidth}px` : 'auto',
              zIndex: 9999,
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.15s ease-in-out',
            }}
            className={`shadow-lg overflow-y-auto pb-2 ${headerButton ? "" : "pt-2"} h-40 bg-surface-primary text-input-border-color-hover rounded border border-solid border-border-primary `}
          >
  
            {headerButton && (
              <div className="sticky top-0 z-10 px-4 py-2 border-b border-border-primary bg-surface-primary flex justify-center">
                  {headerButton}
              </div>
            )}
            <div className="flex-grow overflow-y-auto">
            {filteredItems && filteredItems.length > 0 ? (
              <ul className="">
                {filteredItems?.map((item, index) => (
                  <li key={index} className="px-4 py-2 flex flex-row items-center justify-between hover:bg-selected-shadow group min-h-[40px] hover:border-surface-accent-brand-overlay-primary hover:border-l " onClick={() => { changeSelected(item); setIsOpen(!isOpen); setSearchQuery(""); }}>
                    <div className="flex flex-col items-start justify-start">
                    <div className="text-input-border-color-hover text-linkfont-medium  font-inter group-hover:text-surface-accent-brand-overlay-primary">{item.heading}</div>
                    {showSubtext && item?.description &&
                      <div className="text-subtlest text-body-sm font-normal  font-inter flex flex-row items-center gap-2 group-hover:text-surface-accent-brand-overlay-primary ">
                        <span className="flex flex-row items-center"><InfoIcon size={15} /></span>
                        <span className="flex flex-row items-center">{item.description}</span>
                      </div>
                    }
                    </div>
                    {
                      item?.variableType &&
                      <span className={`${item.variableType === 'JOURNEY'
                          ? 'text-text-accent-purple-primary'
                          : 'text-text-accent-green-primary'
                        } group-hover:text-surface-accent-brand-overlay-primary`}>
                      {item.variableType === 'JOURNEY' ? <JorneyIcon /> : <Environments />}
                    </span>
                    }        
                  </li>
                ))}
              </ul>
            ) : (
              <div className="px-4 py-2  h-full w-full flex items-center justify-center text-sm text-primary-text">{emptyListPlaceHolder}</div>
            )}
          </div>
          </div>
        )}
      </div>
    );
  };