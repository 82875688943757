import { action, computed, makeObservable, observable, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";
/**
 * Store for managing tests and user data.
 */
class TestStore {



  /** Array to store test data. */
  tests = [];
  /** Array to store user data. */
  user = [];

  environments = [];
  currentWorkspaceID = "";


  workSpaceArr = [];
  filterWorkSpaceArr = [];
  filterUserBucketsArr = [];
  filterEnvironments = [];
  filterHistoryArr = [];
  isExistingBucket = true;


  userBuckets = [];

  localRunDependencyProgress = null;

  currentBucketID = "";
  currentBucketFlows = [];
  currentJourneyID = "";
  addFlowToJourney = false;
  addJourneyToBucket = false;
  loading = false;
  initialTestIDs = [];
  passedTestIDs = [];
  historyArr = [];
  createNewEnvironment = false;
  toggleWorkspace = false;
  autoAiSteps = false;
  environmentsToDisplay = [];
  testSuiteName = '';
  testRunningStatus = '';
  stepSuggestion = '';
  domSnapShot = {};
  reportUrl='';
  showReportButton=false;
  showAppOverlay=false;
  journeyFlowStatus=[];
  isTransparent = false;
  stepEditMode = false;
  advConfig = {};
  lastSeqNum = 1;
  showPrevRunDialog = false;
  prevFlowRunStatus = false;
  sideBarAddFlowJourneyId = "";
  localStorageConfig=[];
  updateEnv = false;
  suiteRunJourneys = [];
  showSuiteRunModal = false;
  suiteRun = false;
  suiteRunData = [];
  autoRunLocalStorageType = 'new';
  browserStorage = {};
  flowScreenshots = [];
  currentStepScreenshot = "";
  scriptTypeToBeAdded = "";
  randomFunctionList = [];
  journeyVariableList = [];
  isInbox = "";
  journeyVariables=[];
  stepWasEdited=false;
  editPrePostScript = false;
  query = false;
  browserRecording = null;
  flowBrowserRecordingUploaded = false;
  showPublishSuiteOverlay = false;
  stepAddMode = false;
  showPrePostModal = false;
  newPayload = {};
  currentStepId = "";
  showPrePostScriptModal = false;
  resetDropdown = false;
  runGenerator = null;
  socket = null;
  showCreateNewModal = false;
  showTestModal = false;
  showEditModal = false;
  currentScheduleId='';
  scheduleData=[];
  scheduleFilterData=[];
  showEditURLModal = false;
  firstStepURL = "";
  savedDomSnapshot = [];
  renderDomSnapshot = {};
  disablePrepost = false;
  editWithDom = false;
  editStepData = null;
  storedModalData=null;
  savedDomSnapshot = [];
  renderDomSnapshot = {};
  disablePrepost = false;
  editWithDom = false;
  editStepData = null;
  storedModalData=null;
  envTourOn = false;
  hisTourOn = false;
  flowTourOn = false;
  showTutorialModal = false;
  triggerTutorialFromSideBar = false;
  currentJourneyDefaultEnv = "";
  visualAssertionData=null;
  journeyRecordRes = {
    name: "Desktop",
    res: { height:720, width:1280 },
    userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36"
  }
  disableTutorialButton = false;
  tourBackFromTest = false;
  showTestRunModal = false;
  inHistoryToSeeReport = false;
  tutorialBucketId = "";
  scheduleEditMode = false;
  editScheduleData = {};
  userData={};
  tutorialEnvId = "";
  showConsoleStepModal = false;
  consoleStepModalMode = 'add';
  currentEditingConsoleStep = null;
  isEditDomStep =false;
  editInputDom =false;
  storedInputSteps =null;
  showSnapshotMessage=false;
  disableCaptureDOMSnapshots = true;
  spaceWidth =0;
  flowName ='';
  lineWidth ='';
  updatedDomSnapshot = null;
  showRunProgressButton = false;
  tutTestRunning = false;
  showReportButton = false;
  primaryRunItems = [];
  primaryRunItemsIntervals = [];
  location = "/";
  tutorialRunStatus = "RUNNING";
  bannerVisible = false;
  showImage = false;
  statusMessage = null;
  expandedTimeSavingCard = false;
  expandedScoreCard = false;
  organizationUrl = "";
  orgUrlModalOpen = false;
  scoreCardData = null;
  loadingScoreCard = false;
  reanalyzeScoreCard = false;
  csvList = [];
  invalidEnvironment = "";
  pollingIntervalMap = new Map();
  timeSaved = 0;
  timeSavedLoading = false;
  teamMembers = {};
  workspacesForIssues = [];
  analyticsDateRange = {
    fromDate: "",
    toDate: ""
  };
 

  analyticsChartData = {};
  allHistoryData = [];
  currentSelectedIssue = null;
  toggleIssueResolution = false;
  comingFromIssue = false;
  stepHighlightIndex =null;
  timeSavedWithDateRange = null;
  healListRunCard = []
  shouldFetchForWorkspace = false;
  editedPwStatement = [];
  showSaveModal = false;
  editDiscard = false;
  loadingTimeAndRunStats = false;
  allServicesStatus="Waiting";
  tasks = [
    {
      name: "Create a Test Suite",
      status: "PENDING",
    },
    {
      name: "Create a Journey",
      status: "PENDING",
    },
    {
      name: "Run a Journey",
      status: "PENDING",
    },
    {
      name: "Invite a Team Member",
      status: "PENDING",
    }
  ];

  showInvitePaywall = false;

  services = [
    {
      name: 'Backend Service',
      url: `${process.env.REACT_APP_STUDIO_BASE_URL}/api/v1/health`,
      status: 'Unknown'
    },
    {
      name: 'Auth Service',
      url: `${process.env.REACT_APP_ELECTRON_AUTH_BASE_URL}/api/v1/auth/health`,
      status: 'Unknown'
    }
  ];

  credits = 0;
  useCreditsForRun = false;
  showRunPermissionError = false;
  refetchIssues = false;
  showReloadModal = false;
  issueFilter = "pending";
  journeysToBeRun = [];

  analysisFailed = false;
  editingWebAnalysisUrl = false;
  loadingTillPending = false;
  hoverElement = false;
  staticJourneyVariableList = [];

  /**
   * Creates an instance of TestStore.
   */
  constructor() {
    makeObservable(this, {
      staticJourneyVariableList: observable,
      journeysToBeRun: observable,
      loadingTillPending: observable,
      editingWebAnalysisUrl: observable,
      analysisFailed: observable,
      hoverElement:observable,
      showReloadModal: observable,
      issueFilter: observable,
      refetchIssues: observable,
      loadingTimeAndRunStats: observable,
      shouldFetchForWorkspace: observable,
      healListRunCard: observable,
      timeSavedWithDateRange: observable,
      stepHighlightIndex: observable,
      comingFromIssue: observable,
      currentSelectedIssue: observable,
      toggleIssueResolution: observable,
      allHistoryData: observable,
      analyticsChartData: observable,
      analyticsDateRange: observable,
      workspacesForIssues: observable,
      teamMembers: observable,
      timeSavedLoading: observable,
      timeSaved: observable,
      showRunPermissionError: observable,
      csvList: observable,
      invalidEnvironment: observable,
      editDiscard: observable,
      showSaveModal: observable,
      editedPwStatement: observable,
      useCreditsForRun: observable,
      credits: observable,
      services: observable,
      allServicesStatus: observable,
      showInvitePaywall: observable,
      tasks: observable,
      pollingIntervalMap: observable,
      reanalyzeScoreCard: observable,
      loadingScoreCard: observable,
      scoreCardData: observable,
      orgUrlModalOpen: observable,
      organizationUrl: observable,
      expandedTimeSavingCard: observable,
      expandedScoreCard: observable,
      tutorialRunStatus: observable,
      updateEnv: observable,
      bannerVisible: observable,
      statusMessage: observable,
      showImage: observable,
      location: observable,
      primaryRunItemsIntervals: observable,
      primaryRunItems: observable,
      showReportButton: observable,
      tutTestRunning: observable,
      lineWidth: observable,
      flowName: observable,
      spaceWidth: observable,
      updatedDomSnapshot: observable,
      localRunDependencyProgress: observable,
      consoleStepModalMode: observable,
      currentEditingConsoleStep: observable,
      showConsoleStepModal: observable,
      showSnapshotMessage: observable,
      storedInputSteps: observable,
      editInputDom: observable,
      isEditDomStep: observable,
      storedModalData: observable,
      editStepData: observable,
      editWithDom: observable,
      disablePrepost: observable,
      renderDomSnapshot: observable,
      savedDomSnapshot: observable,
      tutorialEnvId: observable,
      tutorialBucketId: observable,
      inHistoryToSeeReport: observable,
      showTestRunModal: observable,
      tourBackFromTest: observable,
      disableTutorialButton: observable,
      showTutorialModal: observable,
      triggerTutorialFromSideBar: observable,
      flowTourOn: observable,
      hisTourOn:observable,
      envTourOn: observable,
      showTestModal: observable,
      showCreateNewModal: observable,
      currentJourneyDefaultEnv: observable,
      userData: observable,
      editScheduleData: observable,
      scheduleEditMode: observable,
      scheduleData: observable,
      scheduleFilterData: observable,
      currentScheduleId: observable,
      showPrePostScriptModal: observable,
      currentStepId: observable,
      showPrePostModal: observable,
      resetDropdown: observable,
      showEditModal: observable,
      query: observable,
      browserRecording: observable,
      stepWasEdited: observable,
      journeyVariableList: observable,
      randomFunctionList: observable,
      journeyVariables: observable,
      isInbox: observable,
      browserStorage: observable,
      autoRunLocalStorageType: observable,
      localStorageConfig: observable,
      sideBarAddFlowJourneyId: observable,
      advConfig: observable,
      isTransparent: observable,
      stepEditMode: observable,
      autoAiSteps: observable,
      stepSuggestion: observable,
      domSnapShot: observable,
      testSuiteName: observable,
      testRunningStatus: observable,
      environmentsToDisplay: observable,
      tests: observable,
      createNewEnvironment: observable,
      passedTestIDs: observable,
      initialTestIDs: observable,
      loading: observable,
      user: observable,
      environments: observable,
      toggleWorkspace: observable,
      currentWorkspaceID: observable,
      userBuckets: observable,
      currentBucketID: observable,
      currentJourneyID: observable,
      currentBucketFlows: observable,
      addFlowToJourney: observable,
      addJourneyToBucket: observable,
      workSpaceArr: observable,
      filterWorkSpaceArr: observable,
      filterUserBucketsArr: observable,
      filterEnvironments: observable,
      filterHistoryArr: observable,
      historyArr: observable,
      showReportButton: observable,
      journeyFlowStatus:observable,
      suiteRunJourneys: observable,
      showSuiteRunModal: observable,
      suiteRunData: observable,
      flowScreenshots: observable,
      currentStepScreenshot: observable,
      scriptTypeToBeAdded: observable,
      flowBrowserRecordingUploaded: observable,
      socket:observable,
      showEditURLModal: observable,
      reportUrl: observable,
      showAppOverlay:observable,
      lastSeqNum:observable,
      showPrevRunDialog:observable,
      prevFlowRunStatus:observable,
      suiteRun: observable,
      editPrePostScript: observable,
      showPublishSuiteOverlay:observable,
      stepAddMode: observable,
      newPayload: observable,
      runGenerator: observable,
      isExistingBucket: observable,
      firstStepURL: observable,
      visualAssertionData: observable,
      journeyRecordRes: observable,
      disableCaptureDOMSnapshots: observable,
      showRunProgressButton: observable,
      setLocation: action,
      setNewPayload: action,
      setCurrentJourneyDefaultEnv: action,
      addTest: action,
      removeTest: action,
      numTests: computed,
      updateTest: action,
      addToSelectedSteps: action,
      removeFromSelectedSteps: action,
      deleteSelectedSteps: action,
      setUserBuckets: action,
      setCurrentBucketID: action,
      setCurrentJourneyID: action,
      toggleAddFlowToJourney: action,
      toggleAddJourneyToBucket: action,
      setEnvironments: action,
      setCurrentWorkspaceID: action,
      setWorkspaceArr: action,
      setFilterWorkSpaceArr: action,
      setFilterUserBucketsArr: action,
      setFilterEnvironments: action,
      setFilterHistoryArr: action,
      setLoading: action,
      setInitialTestIDs: action,
      setPassedTestIDs: action,
      setHistoryArr: action,
      setCreateNewEnvironment: action,
      setEnvironmentsToDisplay: action,
      setTestSuiteName: action,
      setToggleWorkspace: action,
      setTestRunningStatus: action,
      setDomSnapShot: action,
      setStepSuggestion: action,
      setAutoAiSteps: action,
      setReportUrl:action,
      setShowReportButton: action,
      setShowAppOverlay: action,
      setJourneyFlowStatus: action,
      updateInputValueAtIndex: action,
      deleteStepAtIndex: action,
      setTransparency: action,
      updateValueAtIndex: action,
      setStepEditMode: action,
      setStepAddMode: action,
      setAdvConfig: action,
      setLastSeqNum: action,
      setShowPrevRunDialog: action,
      setPrevFlowRunStatus: action,
      setSideBarAddFlowJourneyId: action,
      setLocalStorageConfig: action,
      setSuiteRunJourneys: action,
      setShowSuiteRunModal: action,
      setSuiteRun: action,
      setSuiteRunData: action,
      setAutoRunLocalStorageType: action,
      setBrowserStorage: action,
      setFlowScreenshots: action,
      setIsInbox: action,
      setJourneyVariables: action,
      setRandomFunctionList: action,
      setJourneyVariableList : action,
      setStepWasEdited : action,
      setEditPrePostScript: action,
      setQuery: action,
      setShowPublishSuiteOverlay: action,
      setBrowserRecording: action,
      setFlowBrowserRecordingUploaded: action,
      setShowPrePostModal: action,
      setCurrentStepId: action,
      setShowPrePostScriptModal: action,
      setResetDropdown: action,
      setRunGenerator: action,
      setIsExistingBucket: action,
      setSocket: action,
      setShowCreateNewModal: action,
      setShowTestModal: action,
      setShowEditModal: action,
      setCurrentScheduleId: action,
      setScheduleData:action,
      setScheduleFilterData:action,
      setShowEditURLModal: action,
      setFirstStepURL: action,
      setEnvTourOn: action,
      setHisTourOn: action,
      setFlowTourOn: action,
      setTriggerTutorialFromSideBar: action,
      setShowTutorialModal: action,
      setVisualAssertionData: action,
      setJourneyRecordRes: action,
      setDisableTutorialButton: action,
      setTourBackFromTest: action,
      setShowTestRunModal: action,
      setInHistoryToSeeReport: action,
      setTutorialBucketId: action,
      setScheduleEditMode: action,
      setEditScheduleData: action,
      setUserData: action,
      setShowConsoleStepModal: action,
      setTutorialEnvId: action,
      setSavedDomSnapshot: action,
      setRenderDomSnapshot: action,
      setDisablePrepost: action,
      setEditWithDom: action,
      setEditStepData: action,
      setStoredModalData: action,
      setIsEditDomStep: action,
      setEditInputDom: action,
      setStoredInputSteps: action,
      setShowSnapshotMessage: action,
      setDisableCaptureDOMSnapshots: action,
      setSpaceWidth: action,
      setFlowName: action,
      setLineWidth: action,
      setLocalRunDependencyProgress: action,
      setShowRunProgressButton: action,
      setUpdatedDomSnapshot: action,
      setTutTestRunning: action,
      setShowReportButton: action,
      setPrimaryRunItems: action,
      setPrimaryRunItemsIntervals: action,
      setTutorialRunStatus: action,
      setUpdateEnv: action,
      setBannerVisible: action,
      setShowImage: action,
      setStatusMessage: action,
      setExpandedTimeSavingCard: action,
      setExpandedScoreCard: action,
      setOrganizationUrl: action,
      setOrgUrlModalOpen: action,
      setScoreCardData: action,
      setLoadingScoreCard: action,
      setReanalyzeScoreCard: action,
      setCsvList: action,
      setInvalidEnvironment: action,
      setPollingIntervalMap: action,
      resetPollingIntervalMap: action,
      setTimeSaved: action,
      setTimeSavedLoading: action,
      setTeamMembers: action,
      setWorkspacesForIssues: action,
      setAnalyticsDateRange: action,
      setAnalyticsChartData: action,
      setAllHistoryData: action,
      setCurrentSelectedIssue: action,
      setToggleIssueResolution: action,
      setComingFromIssue: action,
      setStepHighlightIndex: action,
      setTimeSavedWithDateRange: action,
      setHealListRunCard: action,
      setShouldFetchForWorkspace: action,
      setEditedPwStatement: action,
      setShowSaveModal: action,
      setEditDiscard: action,
      setLoadingTimeAndRunStats: action,
      setTasks: action,
      setShowInvitePaywall: action,
      setAllServicesStatus: action,
      setServices: action,
      setCredits: action,
      setUseCreditsForRun: action,
      setShowRunPermissionError: action,
      setRefetchIssues: action,
      setIssueFilter: action,
      addJourneysToBeRun: action,
      clearJourneysToBeRun: action,
      setHoverElement:action,
      setShowReloadModal: action,
      setAnalysisFailed: action,
      setEditingWebAnalysisUrl: action,
      setLoadingTillPending: action,
      setStaticJourneyVariableList: action,
    });
  }




  clearJourneysToBeRun() {
    this.journeysToBeRun = [];
  }

  addJourneysToBeRun(journeys) {

    const updatedMap = new Map();

    // Add all objects from the old array to the map
    this.journeysToBeRun.forEach(item => updatedMap.set(item.id, item));

    // Add or replace objects from the new array in the map
    journeys.forEach(item => updatedMap.set(item.id, item));

    // Convert the map back to an array
    this.journeysToBeRun = Array.from(updatedMap.values());
  }
  
  setStaticJourneyVariableList(list){
    this.staticJourneyVariableList = list;
  }

  setHoverElement(hover) {
    this.hoverElement = hover;
  }
  setLoadingTillPending(bool){
    this.loadingTillPending = bool;
  }

  setEditingWebAnalysisUrl(bool){
    this.editingWebAnalysisUrl = bool;
  }

  setAnalysisFailed(bool){
    this.analysisFailed = bool;
  }

  setShowReloadModal(val){
    this.showReloadModal = val;
  }

  setIssueFilter(filter){
    this.issueFilter = filter;
  }


  setRefetchIssues() {
    this.refetchIssues = !this.refetchIssues;
  }

  setLoadingTimeAndRunStats(bool) {
    this.loadingTimeAndRunStats = bool;
  }



  setShouldFetchForWorkspace(bool) {
    this.shouldFetchForWorkspace = bool;
  }

  setHealListRunCard(data) {
    this.healListRunCard = data;
  }

  setTimeSavedWithDateRange(data) {
    this.timeSavedWithDateRange = data;
  }

  setStepHighlightIndex(bool) {
    this.stepHighlightIndex = bool;
  }

  setComingFromIssue(bool) {
    this.comingFromIssue = bool;
  }


  setCurrentSelectedIssue(issue) {
    this.currentSelectedIssue = issue;
  }

  setToggleIssueResolution() {
    this.toggleIssueResolution = !this.toggleIssueResolution;
  }

  setAllHistoryData(data) {
    this.allHistoryData = data;
  }

  setAnalyticsChartData(data) {
    this.analyticsChartData = data;
  }

 

  

  setAnalyticsDateRange(fromDate, toDate) {
    this.analyticsDateRange = { fromDate, toDate };
  }


  setWorkspacesForIssues(workspaces) {
    this.workspacesForIssues = workspaces;
  }

  setTeamMembers(members) {
    this.teamMembers = members;
  }

 
  setTimeSavedLoading(loading) {
    this.timeSavedLoading = loading;
  }


  setTimeSaved(time) {
    this.timeSaved = time;
  }


      
  

  setUseCreditsForRun(val){
    this.useCreditsForRun = val;
  }

  setCredits(credits){
    this.credits = credits;
  }

  setServices(services){
    this.services = services;
  }

  setAllServicesStatus(status){
    this.allServicesStatus = status;
  }

  setShowInvitePaywall(val){
    this.showInvitePaywall = val;
  }

  setTasks(tasks){
    this.tasks = tasks;
  }


  setCsvList(list){
    this.csvList = list;
  }

  setInvalidEnvironment(env){
    this.invalidEnvironment = env;
  }

  
  setEditDiscard(val) {
    this.editDiscard =val;
  }
  
  
  setShowSaveModal(val) {
    this.showSaveModal =val;
  }
  
  
  setEditedPwStatement(val) {
    this.editedPwStatement =val;
  }

  resetPollingIntervalMap() {
    this.pollingIntervalMap = new Map();
  }

  setPollingIntervalMap(key, value){
    this.pollingIntervalMap.set(key, value);
  }
  
  setReanalyzeScoreCard(val){
    this.reanalyzeScoreCard = val;
  }


  setLoadingScoreCard(val){
    this.loadingScoreCard = val;
  }


  setScoreCardData(data){
    this.scoreCardData = data;
  }

  setOrgUrlModalOpen(val){
    this.orgUrlModalOpen = val;
  }

  setOrganizationUrl(url){
    this.organizationUrl = url;
  }

  setExpandedScoreCard(val){
    this.expandedScoreCard = val;
  }


  setExpandedTimeSavingCard(val){
    this.expandedTimeSavingCard = val;
  }


  setShowRunPermissionError(val){
    this.showRunPermissionError = val;
  }


  setTutorialRunStatus(status) {
    this.tutorialRunStatus = status;
  }

  setStatusMessage(message) {
    this.statusMessage = message;
  }

  setShowImage(showImage) {
    this.showImage = showImage;
  }

  setBannerVisible(value) {
    this.bannerVisible = value;
  }

  setUpdateEnv(val){
    this.updateEnv = val;
  }


  setLocation(location) {
    this.location = location;
  }



  setPrimaryRunItemsIntervals(intervals){
    console.log("dhol jageero da ", intervals);
    this.primaryRunItemsIntervals = [...intervals];
  }

  setPrimaryRunItems(items){
    items.map((item)=>console.log("blehhh ", toJS(item)));
    this.primaryRunItems = [...items];
  }

  setShowReportButton(val){
    this.showReportButton = val;
  }

  setTutTestRunning(val){
    this.tutTestRunning = val;
  }

  setUpdatedDomSnapshot(dom){
    this.updatedDomSnapshot = dom;
  }

  setShowRunProgressButton(data){
    this.showRunProgressButton = data;
  }

  setLocalRunDependencyProgress(data){
    this.localRunDependencyProgress = data;
  }

  setShowConsoleStepModal(bool, mode = 'add', existingStep = null) {
    this.showConsoleStepModal = bool;
    this.consoleStepModalMode = mode;
    this.currentEditingConsoleStep = existingStep;
  }

  setLineWidth(data){
    this.lineWidth = data;
  }

  setFlowName(data){
    this.flowName = data;
  }

  setSpaceWidth(data){
    this.spaceWidth = data;
  }

  setDisableCaptureDOMSnapshots(data){
    this.disableCaptureDOMSnapshots = data;
  }
  
  setShowSnapshotMessage(data){
    this.showSnapshotMessage = data;
  }

  setStoredInputSteps(data){
    this.storedInputSteps = data;
  }

  setEditInputDom(data){
    this.editInputDom = data;
  }

  setIsEditDomStep(data){
    this.isEditDomStep = data;
  }
  
  setStoredModalData(data){
    this.storedModalData = data;
  }
  
  setEditStepData(step){
    this.editStepData = step;
  }
  
  
  setEditWithDom(dom){
    this.editWithDom = dom;
  }
  
  
  setDisablePrepost(dis){
    this.disablePrepost = dis;
  }

  setTutorialEnvId(id){
    this.tutorialEnvId = id;
  }

  setTutorialBucketId(id){
    this.tutorialBucketId = id;
  }

  setInHistoryToSeeReport(bool){
    this.inHistoryToSeeReport = bool;
  }

  setShowTestRunModal(bool){
    this.showTestRunModal = bool;
  }

  setTourBackFromTest(bool){
    this.tourBackFromTest = bool;
  }

  setDisableTutorialButton(bool){
    this.disableTutorialButton = bool;
  }

  setShowTutorialModal(bool){
    this.showTutorialModal = bool;
  }

  setTriggerTutorialFromSideBar(){
    this.triggerTutorialFromSideBar = !this.triggerTutorialFromSideBar;
  }

  setFlowTourOn(bool){
    this.flowTourOn = bool;
  }

  setHisTourOn(bool){
    this.hisTourOn = bool;
  }

  setEnvTourOn(bool){
    this.envTourOn = bool;
  }

  setUserData(data) {
    this.userData = data;
  }
  
  setScheduleEditMode(mode){
    this.scheduleEditMode = mode;
  }
  
  setEditScheduleData(data){
    this.editScheduleData = data;
  }
  
  setCurrentJourneyDefaultEnv(env){
    console.log("env changed to ", toJS(env));
    this.currentJourneyDefaultEnv = env;
  }
  
  setFirstStepURL(url){
    this.firstStepURL = url;
  }
  
  setSavedDomSnapshot(dom){
    this.savedDomSnapshot = dom;
  }
  
  setRenderDomSnapshot(dom){
    this.renderDomSnapshot = dom;
  }

  setShowEditURLModal(val){
    this.showEditURLModal = val;
  }

  setScheduleFilterData(data){
    this.scheduleFilterData=data;
  }
  
  setScheduleData(data){
    this.scheduleData=data;
  }
  setResetDropdown(){
    this.resetDropdown = !this.resetDropdown
  }

  setShowPrePostScriptModal(bool){
    this.showPrePostScriptModal = bool;
  }

  setCurrentStepId(stepId){
    this.currentStepId = stepId;
  }

  setNewPayload(payload){
    this.newPayload = payload;
  }

  setShowPrePostModal(bool){
    this.showPrePostModal = bool;
  }

  setShowEditModal(bool){
    this.showEditModal = bool;
  }

  setShowTestModal(bool){
    this.showTestModal = bool;
  }

  setShowCreateNewModal(bool){
    this.showCreateNewModal = bool;
  }

  setSocket(socket){
    this.socket = socket;
  }
  setCurrentScheduleId(id){
    this.currentScheduleId = id;
  }

  setIsExistingBucket(val){
    console.log("isExisting bucket set ", val);
    this.isExistingBucket = val;
  }


  setJourneyRecordRes(data){
    this.journeyRecordRes = data;
  }

  setVisualAssertionData(data){
    this.visualAssertionData = data;
  }

  setQuery(query){
    this.query = query;
  }
  
  setFlowBrowserRecordingUploaded(val){
    this.flowBrowserRecordingUploaded = val;
  }

  setRunGenerator(fn){
    this.runGenerator = fn;
  }

  setBrowserRecording(data){
    this.browserRecording = data;
  }

  setShowPublishSuiteOverlay(val){
    this.showPublishSuiteOverlay = val;
  }

  setStepWasEdited(){
    this.stepWasEdited = !this.stepWasEdited;
  }

  setRandomFunctionList(randomFunctionList){
    this.randomFunctionList = randomFunctionList;
  }

  setJourneyVariableList(journeyVariableList){
    this.journeyVariableList = journeyVariableList;
  }

  setScriptTypeToBeAdded(scriptTypeToBeAdded){
    console.log("scriptTypeToBeAdded ", scriptTypeToBeAdded);
    this.scriptTypeToBeAdded = scriptTypeToBeAdded;
  }
  setIsInbox(inbox){
    this.isInbox = inbox;
  }

  setJourneyVariables(variables){
    this.journeyVariables = variables;
  }

  setCurrentStepScreenshot(screenshot){
    this.currentStepScreenshot = screenshot;
  }

  setFlowScreenshots(screenshots){
    this.flowScreenshots = screenshots;
  }

  setSuiteRunData(suiteRunData){
    this.suiteRunData = suiteRunData;
  }

  setEditPrePostScript(val){
    this.editPrePostScript = val;
  }

  setBrowserStorage(storage){
    this.browserStorage = storage;
  }

  setAutoRunLocalStorageType(type){
    this.autoRunLocalStorageType = type;
  }

  setSuiteRun(suiteRun){
    this.suiteRun = suiteRun;
  }

  setSuiteRunJourneys(suiteRunJourneys){
    this.suiteRunJourneys = suiteRunJourneys;
  }
  setShowSuiteRunModal(showSuiteRunModal){
    this.showSuiteRunModal = showSuiteRunModal;
  }

  setLocalStorageConfig(config) {
    this.localStorageConfig = config;
  }
  setSideBarAddFlowJourneyId(journeyId) {
    this.sideBarAddFlowJourneyId = journeyId;
  }

  setStepEditMode(stepEditMode) {
    this.stepEditMode = stepEditMode;
  }
  setStepAddMode(stepAddMode) {
    this.stepAddMode = stepAddMode;
  }
  setTestRunningStatus(status) {
    this.testRunningStatus = status;
  }
  setAutoAiSteps(aiStep) {
    this.autoAiSteps = aiStep;
  }
  setEnvironments(environments) {
    console.log("environemtnws changed to ", environments)
    this.environments = environments;
  }
  setCreateNewEnvironment(environments) {
    this.createNewEnvironment = environments;
  }
  setEnvironmentsToDisplay(environments) {
    this.environmentsToDisplay = environments;
  }
  setTestSuiteName(name) {
    this.testSuiteName = name;
  }
  setToggleWorkspace(space) {
    this.toggleWorkspace = space;
  }

  setDomSnapShot(dom) {
    this.domSnapShot = dom;
  }
  setStepSuggestion(step) {
    this.stepSuggestion = step;
  }


  setLoading(load) {
    this.loading = load;
  }

  setPassedTestIDs(ids) {
    this.passedTestIDs.push(ids);
  }
  setInitialTestIDs(ids) {
    this.initialTestIDs.push(ids);
  }


  setShowAppOverlay(bool) {
    this.showAppOverlay = bool;
  }

  setLastSeqNum(num){
    this.lastSeqNum = num;
  }

  setCurrentWorkspaceID(currentWorkspaceID) {
    this.currentWorkspaceID = currentWorkspaceID;
  }
  setWorkspaceArr(workSpaceArr) {
    this.workSpaceArr = workSpaceArr;
  }
  setFilterWorkSpaceArr(filterWorkSpaceArr) {
    this.filterWorkSpaceArr = filterWorkSpaceArr;
  }
  setFilterUserBucketsArr(filterUserBucketsArr) {
    this.filterUserBucketsArr = filterUserBucketsArr;
  }
  setFilterEnvironments(filterEnvironments) {
    this.filterEnvironments = filterEnvironments;
  }
  setFilterHistoryArr(filterHistoryArr) {
    this.filterHistoryArr = filterHistoryArr;
  }
  setHistoryArr(historyArr) {
    this.historyArr = historyArr;
  }

  setUserBuckets(userBuckets) {
    console.log("user buckets set ", toJS(userBuckets));
    this.userBuckets = userBuckets;
  }

  setCurrenBucketFlows(flows) {
    this.currentBucketFlows = flows;
  }

  setCurrenBucketFlows(flows) {
    this.currentBucketFlows = flows;
  }

  setShowPrevRunDialog(val){
    this.showPrevRunDialog = val;
  }

  setPrevFlowRunStatus(val){
    this.prevFlowRunStatus = val;
  }

  setShowReportButton(val){
    this.showReportButton = val;
  }

  setReportUrl(url){
    console.log("in setReportUrl ", url);
    this.reportUrl = url;
  }

  setCurrentBucketID(currentBucketID) {
    this.currentBucketID = currentBucketID;
    this.currentBucket = toJS(testStore.userBuckets).find(
      (bucket) => bucket.id === currentBucketID
    );
    this.currentJourney = this.currentBucket?.journeys.filter(
      (journey) => journey.id === testStore.currentJourneyID
    );
  }
  setCurrentJourneyID(currentJourneyID) {
    this.currentJourneyID = currentJourneyID;
  }
  toggleAddFlowToJourney(addFlowToJourney) {
    this.addFlowToJourney = addFlowToJourney;
  }

  toggleAddJourneyToBucket(addJourneyToBucket) {
    this.addJourneyToBucket = addJourneyToBucket;
  }

  setAdvConfig(advConfig){
    this.advConfig = { ...advConfig };
  }

  /**
   * Gets the number of tests in the store.
   * @returns {number} The number of tests.
   */
  get numTests() {
    return this.tests.length;
  }



  /**
   * Adds a user to the store.
   * @param {object} user - The user object to add.
   */
  addUser(user) {
    this.user.push(user);
  }



  addToSelectedSteps(index) {
    
  }
  removeFromSelectedSteps(index) {
    
  }
  addToselect(index) {
  
  }
  deleteSelectedSteps() {
  }

  updateInputValueAtIndex(index, newDataValue) {
   
  }

  updateValueAtIndex(index, newDataValue) {
  
  }

  deleteStepAtIndex(index) {    
              
  }

  setJourneyFlowStatus(data){
    this.journeyFlowStatus = data;
  }

  setTransparency(transparency) {
    this.isTransparent = transparency;
}

  /**
   * Adds a test to the store.
   * @param {string} testName - The name of the test.
   * @param {string} url - The URL associated with the test.
   * @param {string} testSteps - The steps of the test.
   * @param {string} deviceProfile - The device profile used in the test.
   * @param {string} createdAt - The timestamp when the test was created.
   * @param {string} lastUpdate - The timestamp of the last update to the test.
   */
  addTest(testName, url, testSteps, deviceProfile, createdAt, lastUpdate) {
    const newTest = {
      testName,
      testId: uuidv4(),
      url,
      testSteps,
      deviceProfile,
      createdAt,
      lastUpdate,
    };
    this.tests.push(newTest);
  }

  /**
   * Updates a test in the store.
   * @param {string} testId - The ID of the test to update.
   * @param {object} newData - The new data to update the test with.
   */
  updateTest(testId, newData) {
    const testIndex = this.tests.findIndex((t) => t.testId === testId);
    if (testIndex > -1) {
      this.tests[testIndex] = { ...this.tests[testIndex], ...newData };
    }
  }

  /**
   * Removes a test from the store.
   * @param {string} testId - The ID of the test to remove.
   */
  removeTest(testId) {
    const testIndex = this.tests.findIndex((t) => t.testId === testId);
    if (testIndex > -1) {
      this.tests.splice(testIndex, 1);
    }
  }
}

const testStore = new TestStore();

export default testStore;
