export const container = "relative mx-auto flex max-w-6xl flex-col items-center text-center ";
export const introWrapper = "z-1 relative mb-8 sm:mb-16 flex flex-col items-center gap-4";
export const introBox = "relative inline-flex items-center justify-center rounded-full text-secondary-color-light/50 border border-yellow-800 bg-gradient-to-b from-zinc-950 via-zinc-950 px-4 py-2 text-sm shadow-md backdrop-blur-md transition hover:border-yellow-700 hover:to-white/5 font-display font-normal";
export const introbg = "inline-flex max-w-max items-center justify-center bg-gradient-to-br from-zinc-950 to-zinc-600 bg-clip-text text-transparent"
export const introIcon = "inline-flex max-w-max items-center justify-center text-white mr-2";
export const mainHeading = "mb-8 block max-w-max bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text text-transparent font-normal";
export const subHeading = "max-w-md text-zinc-400/90 md:max-w-2xl font-display font-normal";
export const buttonContainer = "relative mx-auto max-w-xs space-y-2 grid grid-cols-1 sm:grid-cols-2 sm:max-w-none sm:justify-center sm:space-x-2 sm:space-y-0 sm:w-[90%] md:w-full";
export const animatedButton = "relative inline-flex overflow-hidden group rounded-full p-[1px]";
export const spinner = "absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,_theme(colors.zinc.900)_0%,_theme(colors.zinc.600)_50%,_theme(colors.zinc.900)_100%)]";
export const buttonText = "inline-flex items-center justify-center w-full h-full px-4 py-2 text-sm text-zinc-50 rounded-full cursor-pointer animate-background-shine bg-[linear-gradient(110deg,_theme(colors.zinc.950),45%,_theme(colors.zinc.900),55%,_theme(colors.zinc.950))] bg-[length:200%_100%] backdrop-blur-md";
export const arrowIcon = "ml-2 text-secondary-color-light/70 group-hover:translate-x-0.5 transition-transform";
export const secondaryButton = "inline-flex w-full items-center justify-center rounded-full border border-zinc-500/20 bg-white bg-opacity-5 px-4 py-2 text-sm text-zinc-200 backdrop-blur-md transition hover:border-zinc-500/20 hover:bg-opacity-10 hover:text-zinc-50 cursor-pointer";
