import React, { useState } from "react";
import { OutlineErrorOutline } from "../../../../assets/shared_icons";

/**
 * A text input component with various states (error, warning, success) and helper text.
 * Includes functionality for focus, disabled state, and optional validation messaging.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.label="Field label"] - The label displayed above the text input.
 * @param {string} [props.placeholder="Start typing here"] - Placeholder text for the input field.
 * @param {string} [props.warningText=""] - Warning message displayed if input state has a warning.
 * @param {string} [props.errorText=""] - Error message displayed if input state has an error.
 * @param {string} [props.successText=""] - Success message displayed if the input is valid.
 * @param {boolean} [props.required=false] - Whether the input field is required or not.
 * @param {boolean} [props.disabled=false] - Whether the input field is disabled or not.
 * @param {string} props.value - The current value of the input field.
 * @param {function} props.onChange - Callback function to handle changes to the input field.
 * 
 * @returns {JSX.Element} A controlled text input component with focus, error, and warning states.
 */
const TextInputWithState = ({
  id,
  placeholder = "Start typing here",
  warningText = "",
  errorText = "",
  required = false,
  disabled = false,
  value,
  onChange,
  inputType = "text",
  height = "",
  ...additionalProps
}) => {
  const [isFocused, setIsFocused] = useState(false);


  return (
    <div className="w-full">
      {/* Input container with conditional border colors based on state */}
      <div
        className={`px-2 py-1.5 relative mt-1 rounded shadow-sm bg-Surface-Raised-Primary border flex items-center ${height}  ${
          errorText
            ? "border-red-500"
            : warningText
            ? "border-yellow-400"
            : isFocused
            ? "border-surface-accent-brand-overlay-primary/60"
            : "border-input-border-color"
        }
         ${
            !errorText && !warningText && !isFocused
              ? "hover:bg-shadow-bg-hover"
              : ""
          }
        `}
      >
        {/* Input field */}
        <input
          id={id}
          type={inputType}
          className={`block w-full bg-transparent text-white font-normal text-lable border-none focus:ring-0 outline-none text-body-md  ${
            disabled ? "cursor-not-allowed" : ""
          }`}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...additionalProps}
        />
      </div>

      {/* Error or Warning Text */}
      {
        errorText || warningText &&
        <p className="mt-1 text-sm">
          {errorText ? (
            <span className="flex items-center gap-1 text-text-accent-red-primary  font-normal text-caption">
              <OutlineErrorOutline
                size={16}
                className="text-text-accent-red-primary"
              />
              {errorText}
            </span>
          ) : warningText ? (
            <span className="flex text-yellow-400">{warningText}</span>
          ) : null}
        </p>
      }
    </div>
  );
};

export default TextInputWithState;
