import React,{forwardRef, useState} from 'react'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';


/**
 * A Horizontal Accordion.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} [props.icon] - The icon to display 
 * @param {boolean} [props.isActive] 
 * @param {string} [props.summaryCustomClass] 
 * @param {string} [props.childStyle] 
 * @param {string} [props.customLineStyle] 
 * @param {Function} [props.onAccordionClick] 
 * @param {Function} [props.onSummaryClick] 
 * @param {Function} [props.onToggle] 
 * @param {boolean} [props.isCustomStyle] 
 * @param {boolean} [props.isLineIndicator] 
 * @param {boolean} [props.isArrow] 
 * @param {object} [props.customStyle] 
 * @param {React.ReactNode} [props.titleElements] 
 * 
 */

function HorizontalAccordion(props,ref) {

    const [isOpen,setIsOpen]=useState(props?.isActive);

    const handleToggle=(e)=>{
        e.stopPropagation();
        setIsOpen(e.target.open);
        props?.onToggle&&props?.onToggle(e.target.open)
    }

    return (
        <li key={props.id} ref={ref}
            onClick={props?.onAccordionClick}
            className={`${!props?.isLineIndicator?'remove_arrow':''}`}
            >
            <details className={`w-full `} onToggle={handleToggle}  open={isOpen}>
                <summary className={`flex md:gap-2 items-center group  ${props?.isActive ?"text-secondary-brand-primary " :"text-primary-text "} ${props.isArrow&&isOpen?'bg-alpha-700':''} font-semibold flex w-full ${props?.summaryCustomClass?props?.summaryCustomClass:'p-0 gap-0 py-[8px]'}`} onClick={props?.onSummaryClick}>
                    <span className='flex shrink-0'>
                    {
                         props?.icon && 
                         <span className='flex shrink-0'>
                             {
                                 props?.icon
                             }
                         </span>
                    }
                    </span>
                    {props?.titleElements}
                   {props.isArrow &&
                    <span className='flex shrink-0'>
                        <MdOutlineKeyboardArrowUp size={16} className={`transform transition-transform duration-300 text-primary-text ml-auto ${isOpen? "" : 'rotate-180'}`} />
                    </span>
                    }
                </summary>
                <ul className={`menu menu-xs w-full font-secondary text-base ${props?.childStyle?props?.childStyle:'p-0 ml-0 pl-3'}  relative`}>
                    {props?.isLineIndicator && <div className={`${props?.customLineStyle} absolute left-[0.45rem] top-1 h-full w-[1px] bg-gradient-to-b from-[#FFDFA1] to-[#F39E1E]`}></div>}
                    {props?.children}
                </ul>
            </details>
        </li>
    )
}

export default forwardRef( HorizontalAccordion);