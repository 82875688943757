import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
 
import { GoUnverified } from "react-icons/go";
import { profile3 } from "../../../api/services/apiService";
import { sdkCreateAccount, sdkVerifyEmail } from "../../../routes/routes";
import SideBar from "../sideBar";
import Header from "./Header";
import testStore from "../../../stores/TestStore";
import { SecondaryButton } from "../../../components/Buttons";
import TextInputWithState from "../../../components/SdkLandingPageComponents/analytics/components/TextFields";
import OverlayModal from "../../../components/AppLandingPageComponents/landingPageComponents/JourneyRunReport/OverlayModal";
import PrimaryButton from "../../../components/SdkLandingPageComponents/analytics/components/PrimaryButton";
import Session from "supertokens-web-js/recipe/session";
import axios from "axios";
import { observer } from "mobx-react";
import { apiClient } from "../../../components/SdkLandingPageComponents/analytics/utility/analyticsutility";


const SdkDashboardLayout = ({ sessionExists, isEmailVerified,logFrom }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [organisationId, setOrganisationId] = useState("");
  const [orgUrlErr, setOrgUrlErr] = useState("");
  const [checkingUrl, setCheckingUrl] = useState(false);
  const [orgUrl, setOrgUrl] = useState("");
  useEffect(() => {
    profile3()
      .then((res) => {
        setUserData(res);
        if (
          !(res.ProfileData.name && res.ProfileData.name.length >= 0) ||
          !res.ProfileData.organisation
        ) {
          navigate(sdkCreateAccount);
        }
      })
      .catch((err) => console.error(err));
  }, []);


  return (
    <div className="flex flex-row h-screen w-screen relative overflow-hidden">
      <SideBar logFrom={logFrom} />
      <div className="flex flex-col bg-black overflow-auto relative grow">
        {!Boolean(isEmailVerified) && (
          <div className="banner absolute top-[3.75rem] left-2 sm:top-2 sm:left-44 text-white z-[990] font-primary font-bold">
            <div role="alert" className="alert alert-warning flex gap-2 px-3 py-2 sm:py-4 sm:px-6 w-80 sm:w-96 md:w-auto">
              <GoUnverified className="text-2xl font-bold" />
              <div className="text-start text-sm sm:text-base">
                Your email is not verified.{" "}
                <a href={sdkVerifyEmail} className="underline">
                  Click here
                </a>{" "}
                to verify your email
              </div>
            </div>
            {/* Your email is not verified.{" "}
            <Link href="#">
              Click here
            </Link>{" "}
            to verify your email. */}
          </div>
        )}
        <Header logFrom={logFrom} userData={userData && userData} />
        <Outlet />
      </div>
    </div>
  );
};

export default observer(SdkDashboardLayout);
