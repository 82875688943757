import { AiFillHome } from "react-icons/ai";
import {
  BsFillArrowRightCircleFill,
  BsPersonFillUp,
  BsThreeDots,
  BsThreeDotsVertical,
  BsPencil
} from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
import { FaArrowLeft, FaArrowRight, FaGithub, FaChevronDown, FaChevronUp, FaLayerGroup, FaRegImage , FaPlay } from "react-icons/fa";
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaNoteSticky,
  FaRegWindowMinimize,
  FaUserGroup
} from "react-icons/fa6";
import { FiBook } from "react-icons/fi";
import { GoHistory, GoHome, GoArrowRight, GoPersonAdd } from "react-icons/go";
import { GrClose, GrValidate } from "react-icons/gr";
import { HiMiniCube } from "react-icons/hi2";
import { ImCancelCircle } from "react-icons/im";
import { VscError } from "react-icons/vsc";
import {
  IoIosLogOut,
  IoLogoGoogle,
  IoMdAdd,
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoIosCheckmarkCircleOutline,
  IoIosWarning,
  IoMdRefresh,
  IoMdPerson
} from "react-icons/io";
import {
  IoEye,
  IoEyeOff,
  IoSearchOutline,
  IoSettingsOutline,
  IoCopyOutline,
  IoInformationCircleSharp,
  IoAnalytics,
  IoBandageSharp
} from "react-icons/io5";
import {
  MdEditSquare,
  MdError,
  MdModeEdit,
  MdOutlineCheckBoxOutlineBlank,
  MdPersonAddAlt1,
  MdPeopleAlt,
  MdWorkspacesOutline,
  MdOutlineTurnRight,
  MdOutlineMarkEmailRead,
  MdOutlineUploadFile,
  MdOutlineMail,
  MdOutlineArrowBack,
  MdOutlineKeyboardArrowUp,
  MdOutlineLayers,
  MdOutlineFunctions,
  MdOutlineHistory,
  MdCalendarToday,
  MdOutlineContentCopy,
  MdOutlineSettings,
  MdOutlineExpandLess,
  MdOutlineChevronRight,
  MdOutlineRefresh,
  MdOutlineAdd,
  MdOutlineEdit,
  MdMoreHoriz,
  MdOutlineDelete,
  MdOutlinePreview,
  MdOutlineDeleteForever,
  MdOutlinePlayArrow,
  MdOutlinePause,
  MdOutlineStop,
  MdOutlineKeyboardArrowDown,
  MdOutlineErrorOutline,
  MdOutlineWaves,
  MdOutlineBugReport,
  MdOutlineWarning,
  MdOutlineUndo,
  MdOutlineRedo,
  MdChevronRight
} from "react-icons/md";
import { RxCrossCircled, RxCross2 } from "react-icons/rx";
import { FaCheckCircle } from "react-icons/fa";
import { PiFlowArrowBold, PiLockFill, PiCursorBold } from "react-icons/pi";
import { RiCheckboxMultipleBlankLine, RiDeleteBin6Line } from "react-icons/ri";
import { RxAvatar } from "react-icons/rx";
import {
  TbBucket,
  TbDeviceImacCode,
  TbPlayerRecordFilled,
  TbVariablePlus,
  TbWorldWww,
  TbPlayerTrackNext
} from "react-icons/tb";
import { PiWarningCircle } from "react-icons/pi";
import { VscHistory, VscSettings, VscCollapseAll } from "react-icons/vsc";
// import ArrowRightIcon from "./Arrow Turn Right.png";
// import createTest from "./create_test.png";
import { TiFlowMerge } from "react-icons/ti";
import { GrPin } from "react-icons/gr";
import { BiError, BiSolidReport } from "react-icons/bi";
import { CiMobile3 } from "react-icons/ci";
import { IoIosTabletLandscape, IoMdPeople } from "react-icons/io";
import { IoIosDesktop } from "react-icons/io";
import { FaFileAlt, FaRegKeyboard } from "react-icons/fa";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { TfiHandDrag } from "react-icons/tfi";
import { IoIosCloudDone } from "react-icons/io";
import { FaGlobe } from "react-icons/fa";
import { SiPlaywright } from "react-icons/si";
import { FaCrown } from "react-icons/fa6";
import { CiCircleInfo } from "react-icons/ci";
import { ImNewTab } from "react-icons/im";
import { HiOutlineAdjustments,HiOutlineRefresh } from "react-icons/hi";
import { GrSchedules } from "react-icons/gr";
import { LuRefreshCw,LuMousePointerClick } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa6";
import { TbCapture } from "react-icons/tb";
import { LiaFlaskSolid } from "react-icons/lia";
import { MdOutlineFitScreen } from "react-icons/md";
import { FiMinus } from "react-icons/fi";
import { TbFocusAuto } from "react-icons/tb";
import { SlOptionsVertical } from "react-icons/sl";
import { GoFileZip } from "react-icons/go";
import { MdOutlineFileDownload } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { IoRocketOutline } from "react-icons/io5";
import { MdArrowOutward } from "react-icons/md";
import { MdOutlineError } from "react-icons/md";
import { FaChrome } from "react-icons/fa";
import { FaFirefoxBrowser } from "react-icons/fa";
import { FaSafari } from "react-icons/fa6";
import { FaEdge } from "react-icons/fa";
import React from 'react';


const automateWheel = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_1042_1119)">
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#FFAF15" fill-opacity="0.6"/>
    <path d="M8 16C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14C6 15.1046 6.89543 16 8 16Z" fill="#FFAF15" fill-opacity="0.6"/>
    <path d="M12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z" fill="#FFAF15" fill-opacity="0.6"/>
    <path d="M16 16C17.1046 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8954 12 14 12.8954 14 14C14 15.1046 14.8954 16 16 16Z" fill="#FFAF15" fill-opacity="0.6"/>
  </g>
  <defs>
    <clipPath id="clip0_1042_1119">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

const BookMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<g clip-path="url(#clip0_1042_1135)">
<path d="M12.5 1.33331H4.49996C3.76663 1.33331 3.16663 1.93331 3.16663 2.66665V13.3333C3.16663 14.0666 3.76663 14.6666 4.49996 14.6666H12.5C13.2333 14.6666 13.8333 14.0666 13.8333 13.3333V2.66665C13.8333 1.93331 13.2333 1.33331 12.5 1.33331ZM6.49996 2.66665H7.83329V5.99998L7.16663 5.49998L6.49996 5.99998V2.66665ZM12.5 13.3333H4.49996V2.66665H5.16663V8.66665L7.16663 7.16665L9.16663 8.66665V2.66665H12.5V13.3333Z" fill="#1A5EE3"/>
</g>
<defs>
<clipPath id="clip0_1042_1135">
<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
);

const LightBulb = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
  <path d="M6.99995 0C6.53995 0 6.06995 0.0400001 5.59995 0.14C2.83995 0.67 0.639949 2.9 0.119949 5.66C-0.360051 8.27 0.59995 10.67 2.33995 12.22C2.76995 12.6 2.99995 13.13 2.99995 13.69V16C2.99995 17.1 3.89995 18 4.99995 18H5.27995C5.62995 18.6 6.25995 19 6.99995 19C7.73995 19 8.37995 18.6 8.71995 18H8.99995C10.0999 18 10.9999 17.1 10.9999 16V13.69C10.9999 13.14 11.2199 12.6 11.6399 12.23C13.0899 10.95 13.9999 9.08 13.9999 7C13.9999 3.13 10.8699 0 6.99995 0ZM8.99995 14H4.99995V13H8.99995V14ZM4.99995 16V15H8.99995V16H4.99995ZM10.3099 10.74C10.2199 10.82 10.1499 10.92 10.0699 11H3.91995C3.83995 10.91 3.76995 10.81 3.67995 10.73C2.35995 9.55 1.76995 7.79 2.08995 6.03C2.44995 4.09 4.04995 2.48 5.97995 2.1C6.31995 2.03 6.65995 2 6.99995 2C9.75995 2 11.9999 4.24 11.9999 7C11.9999 8.43 11.3899 9.79 10.3099 10.74Z" fill="#BDC2C7"/>
  <path d="M7.5 8H6.5V11H7.5V8Z" fill="#BDC2C7"/>
  <path d="M5.37946 5.87372L4.67236 6.58082L6.79366 8.70212L7.50076 7.99502L5.37946 5.87372Z" fill="#BDC2C7"/>
  <path d="M6.50091 8.00518L7.20801 8.71228L9.32931 6.59098L8.62221 5.88388L6.50091 8.00518Z" fill="#BDC2C7"/>
  </svg>
);

const FigmaEditIcon = ({ width = 24, height = 24, fill = "#FFAF15", className = "" }) => (
  <svg 
    width={width} 
    height={height} 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="edit" clipPath="url(#clip0_1622_3842)">
      <path 
        id="Vector" 
        d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z" 
        fill={fill} 
      />
    </g>
    <defs>
      <clipPath id="clip0_1622_3842">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChevronRight = MdChevronRight;
const Chrome = FaChrome;
const Firefox = FaFirefoxBrowser;
const Safari = FaSafari;
const Edge = FaEdge;
const OutlineRedo = MdOutlineRedo;
const OutlineUndo = MdOutlineUndo;
const MousePointerClick = LuMousePointerClick;
const Analytics = IoAnalytics;
const Bandage = IoBandageSharp;
const SolidError = MdOutlineError;
const TopRightArrow = MdArrowOutward;
const WarningCircle = PiWarningCircle;
const OutlineWarning = MdOutlineWarning;
const CollapseAll = VscCollapseAll;
const OnePerson = IoMdPerson;
const People = IoMdPeople;
const Skip = TbPlayerTrackNext;
const OutlineWaves = MdOutlineWaves;
const OutlineBugReport = MdOutlineBugReport;
const OutlineKeyboardArrowDown = MdOutlineKeyboardArrowDown;
const OutlineErrorOutline = MdOutlineErrorOutline;
const OutlineStop = MdOutlineStop;
const OutlinePause = MdOutlinePause;
const OutlinePlayArrow = MdOutlinePlayArrow;
const OutlineDeleteForever = MdOutlineDeleteForever;
const OutlinePreview = MdOutlinePreview;
const OutLineEdit = MdOutlineEdit;
const RefreshIcon = MdOutlineRefresh;
const OutlineChevronRight = MdOutlineChevronRight;
const OutlineExpandLess = MdOutlineExpandLess;
const SettingsNode = MdOutlineSettings;
const CopyNode = MdOutlineContentCopy;
const AutomateWheel = automateWheel;
const AutoLayout = HiOutlineRefresh;
const Download = MdOutlineFileDownload;
const UnzipFile = GoFileZip;
const OutlineKeyboardArrowUp = MdOutlineKeyboardArrowUp;
const OutlineArrowBack = MdOutlineArrowBack;
const VerticalOptions = SlOptionsVertical;
const Minus = FiMinus;
const FitScreen = MdOutlineFitScreen;
const Flask = LiaFlaskSolid;
const Clock = FaRegClock;
const Capture = TbCapture;
const Refresh = LuRefreshCw;
const PlayTutorial = FaPlay;
const Schedules = MdCalendarToday;
const Pwicon = SiPlaywright;
const Report = TbReportAnalytics;
const NewTab = ImNewTab;
const TransparentInfo = CiCircleInfo;
const Crown = FaCrown;
const Cloud = IoIosCloudDone;
const Imagestep = FaRegImage;
const Mailstep = MdOutlineMail;
const Drag = TfiHandDrag;
const Fileupload = MdOutlineUploadFile;
const Clickcursor = PiCursorBold;
const InputKeyboard = FaRegKeyboard;
const Navigate = TbWorldWww;
const AssertCheck = GrValidate;
const Pencil = BsPencil;
const RandomDice = GiPerspectiveDiceSixFacesRandom;
const InfoIcon = IoInformationCircleSharp;
const CopyOutline = IoCopyOutline;
const Github = FaGithub;
const Google = IoLogoGoogle;
const ArrowLeft = FaArrowLeft;
const ArrowLongLeft = FaArrowLeftLong;
const ArrowRight = FaArrowRight;
const ArrowRightCircle = BsFillArrowRightCircleFill;
const ArrowLongRight = FaArrowRightLong;
const ArrowDown = IoMdArrowDropdown;
const ArrowUp = IoMdArrowDropup;
const Eye = IoEye;
const EyeOff = IoEyeOff;
const CancelCircle = ImCancelCircle;
const Error = MdError;
const Close = GrClose;
const CloseSquare = CgCloseR;
const Maximize = RiCheckboxMultipleBlankLine;
const Minmize1 = MdOutlineCheckBoxOutlineBlank;
const Minimize = FaRegWindowMinimize;
const Profile = RxAvatar;
const Logout = IoIosLogOut;
const Home = GoHome;
const HomeFill = AiFillHome;
const RowSettings = VscSettings;
const Settings = IoSettingsOutline;
const History = MdOutlineHistory;
const HorizontallThreeDots = MdMoreHoriz;
const VerticalThreeDots = BsThreeDotsVertical;
const Add = MdOutlineAdd;
const Upgrade = BsPersonFillUp;
// const CreateTest = createTest;
const Environment = FiBook;
const AddPerson = MdPersonAddAlt1;
const SearchOutline = IoSearchOutline;
const ConsoleIcon = TbDeviceImacCode;
const MiniCube = HiMiniCube;
const RecordIcon = TbPlayerRecordFilled;
const LockClosedIcon = PiLockFill;
const NoteIcon = FaNoteSticky;
const BucketIcon = TbBucket;
const JorneyIcon = TiFlowMerge;
const Flow = PiFlowArrowBold;
const DownArrow = FaChevronDown;
const UpArrow = FaChevronUp;
const Workspace = MdPeopleAlt;
const EditIcon = MdModeEdit;
const EditSquareIcon = MdEditSquare;
const History2 = GoHistory;
const Pin = GrPin;
const Workspaces = MdWorkspacesOutline;
const Environments = MdOutlineFunctions;
const SearchRightIcon = MdOutlineTurnRight;
const DeleteIcon = MdOutlineDelete;
const CheckIcon = IoIosCheckmarkCircleOutline;
const CircleCross = VscError;
const RightArrow = GoArrowRight;
const TestSuite = MdOutlineLayers;
const InvitePerson = GoPersonAdd;
const InviteSent = FaUserGroup;
const MailSent = MdOutlineMarkEmailRead;
const ErrorInvite = BiError;
const Mobile = CiMobile3;
const Tablet = IoIosTabletLandscape;
const Desktop = IoIosDesktop;
const Warning = IoIosWarning;
const CheckCircle = FaCheckCircle;
const CrossCircle = RxCrossCircled;
const CrossWithoutCircle = RxCross2;
const File = FaFileAlt;
// const RefreshIcon = IoMdRefresh;
const Globe = FaGlobe;
const Adjustments = HiOutlineAdjustments;
const Rocket = IoRocketOutline;

export {
  SolidError,
  CircleCross,
  TopRightArrow,
  Rocket,
  WarningCircle,
  OutlineWarning,
  OutlineBugReport,
  OutlineWaves,
  OutlineErrorOutline,
  OutlineKeyboardArrowDown,
  OutlineStop,
  OutlinePause,
  OutlinePlayArrow,
  OutlineDeleteForever,
  OutlineArrowBack,
  OutlinePreview,
  OutLineEdit,
  OutlineChevronRight,
  OutlineExpandLess,
  CopyNode,
  SettingsNode,
  LightBulb,
  BookMark,
  AutomateWheel,
  AutoLayout,
  Download,
  UnzipFile,
  OutlineKeyboardArrowUp,
  VerticalOptions,
  Minus,
  Capture,
  Clock,
  Add,
  AddPerson,
  Adjustments,
  ArrowDown,
  ArrowLeft,
  ArrowLongLeft,
  ArrowLongRight,
  ArrowRight,
  ArrowRightCircle,
  // ArrowRightIcon,
  BucketIcon,
  CancelCircle,
  Close,
  Cloud,
  CloseSquare,
  ConsoleIcon,
  // CreateTest,
  Crown,
  Environment,
  Error,
  Eye,
  EyeOff,
  FitScreen,
  File,
  Flask,
  Github,
  Google,
  History,
  Home,
  HomeFill,
  HorizontallThreeDots,
  LockClosedIcon,
  Logout,
  Maximize,
  MiniCube,
  Minimize,
  Minmize1,
  NoteIcon,
  Profile,
  RecordIcon,
  RowSettings,
  SearchOutline,
  Settings,
  Upgrade,
  VerticalThreeDots,
  JorneyIcon,
  Flow,
  DownArrow,
  Workspace,
  EditIcon,
  EditSquareIcon,
  History2,
  Pin,
  Workspaces,
  Environments,
  SearchRightIcon,
  DeleteIcon,
  CheckIcon,
  ArrowUp,
  UpArrow,
  RightArrow,
  TestSuite,
  TransparentInfo,
  InvitePerson,
  InviteSent,
  MailSent,
  ErrorInvite,
  Mobile,
  Tablet,
  Desktop,
  Warning,
  CheckCircle,
  CrossCircle,
  CrossWithoutCircle,
  CopyOutline,
  InfoIcon,
  Pencil,
  RefreshIcon,
  RandomDice,
  Clickcursor,
  InputKeyboard,
  Navigate,
  AssertCheck,
  Mailstep,
  Drag,
  Fileupload,
  Imagestep,
  Globe,
  Pwicon,
  NewTab,
  Report,
  PlayTutorial,
  Schedules,
  Refresh,
  Skip,
  FigmaEditIcon,
  People,
  OnePerson,
  CollapseAll,
  Analytics,
  Bandage,
  Chrome,
  Firefox,
  Safari,
  Edge,
  MousePointerClick,
  OutlineRedo,
  OutlineUndo,
  ChevronRight
};
