import React from "react";
import { GrClose } from "react-icons/gr";


const ModalHeading = ({data}) => {
    return (
        <div className="flex flex-row sm:pb-4 pb-1 justify-between border-b-[1px] border-border-primary">
          <div className="flex flex-row my-auto">
            <h3 className=" text-body-lg font-medium text-primary-color">
              {data.heading}
            </h3>
           
          </div>
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost hover:bg-transparent group text-primary-icon-color p-0" onClick={data.onClickFn}>
              <GrClose className="text-base" />
            </button>
          </form>
        </div>
    )
}

export default ModalHeading;