import { FaMoneyBills } from "react-icons/fa6";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdContacts } from "react-icons/md";
import { TbSettingsCog } from "react-icons/tb";
import { appAccount, appBilling, appProfile, sdkAccount, sdkApiKeys, sdkBilling, sdkProfile } from "../../routes/routes";
export const menus = [
  {
    id: 1,
    name: "Dashboard",
    path: sdkAccount,
    appPath: appAccount,
    icon: <LuLayoutDashboard className="text-xl" />,
  },
  {
    id: 2,
    name: "Billing",
    path: sdkBilling,
    appPath: appBilling,
    icon: <FaMoneyBills className="text-xl" />,
  },
  {
    id: 3,
    name: "API Keys",
    path: sdkApiKeys,
    isHide:true,
    icon: <TbSettingsCog className="text-xl" />,
  },
  {
    id: 4,
    name: "Profile",
    path: sdkProfile,
    appPath:appProfile,
    icon: <MdContacts className="text-xl" />,
  },
];
