import React from 'react'

/**
 * A Badge component that can be used to display status, labels, or counts.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.textClass] - The class for text color.
 * @param {string} [props.bgColorClass] - The class for background color.
 * @param {string} [props.size] - The size of the badge ('sm', 'md', 'lg').
 * @param {string} [props.className] - Additional classes to apply.
 */
function Badge({ 
  textClass, 
  bgColorClass, 
  size = 'md',
  className = '',
  children 
}) {
  const sizeClasses = {
    sm: 'h-[14px] text-[10px] px-[5px]',
    md: 'h-[16px] text-body-xs px-[7px]',
    lg: 'h-[20px] text-xs px-[9px]'
  };

  return (
    <span className={`
      flex items-center font-normal rounded-xl
      ${sizeClasses[size]}
      ${textClass}
      ${bgColorClass}
      ${className}
    `}>
      {children}
    </span>
  )
}

export default Badge